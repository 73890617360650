import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Table, Space, Button, Modal, message } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import {
  deleteSeason,
  deleteCommercialDate,
  getCommercialDates,
  getSeasons,
} from "../../api/Services";

import styles from "./Parameters.module.scss";

const Parameters = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [commercialDates, setCommercialDates] = useState<any[]>([]);
  const [seasons, setSeasons] = useState<any[]>([]);
  const [loadingDates, setLoadingDates] = useState(true);
  const [loadingSeasons, setLoadingSeasons] = useState(true);
  const [actualPageDates, setActualPageDates] = useState(1);
  const [actualPageSeasons, setActualPageSeasons] = useState(1);
  const [totalSizeDates, setTotalSizeDates] = useState(0);
  const [totalSizeSeasons, setTotalSizeSeasons] = useState(0);

  useEffect(() => {
    handleFetchCommercialDates(1);
    handleFetchSeasons(1);
    setActualPageDates(1);
    setActualPageSeasons(1);
  }, []);

  const handleFetchCommercialDates = async (pagination = actualPageDates) => {
    setLoadingDates(true);
    const response = await getCommercialDates(pagination - 1);
    setCommercialDates(response.commercialDates);
    setTotalSizeDates(response.totalElements);
    setLoadingDates(false);
  };

  const handleFetchSeasons = async (pagination = actualPageSeasons) => {
    setLoadingSeasons(true);
    const response = await getSeasons(pagination - 1);
    setSeasons(response.seasons);
    setTotalSizeSeasons(response.totalElements);
    setLoadingSeasons(false);
  };

  const handlePaginationSeasons = (page: number) => {
    handleFetchSeasons(page);
    setActualPageSeasons(page);
  };

  const handlePaginationComercialDates = (page: number) => {
    handleFetchCommercialDates(page);
    setActualPageSeasons(page);
  };

  const handleDeleteSeason = async (id: number) => {
    setLoadingSeasons(true);
    const deleteStatus = await deleteSeason(id);
    if (!deleteStatus) message.error(t("Common-DeleteErrorMessage"));
    else message.success(t("Common-DeleteSuccessMessage"));
    await handleFetchSeasons();
    setLoadingSeasons(false);
  };

  const handleDeleteCommercialDate = async (id: number) => {
    setLoadingDates(true);
    const deleteStatus = await deleteCommercialDate(id);
    if (!deleteStatus) message.error(t("Common-DeleteErrorMessage"));
    else message.success(t("Common-DeleteSuccessMessage"));
    await handleFetchCommercialDates();
    setLoadingDates(false);
  };

  const showDeleteConfirm = (
    itemId: number,
    entity: "season" | "commercialDate"
  ) => {
    Modal.confirm({
      title: t("Common-DeleteConfirm"),
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: t("Common-Yes"),
      okType: "danger",
      cancelText: t("Common-No"),
      onOk() {
        if (entity === "season") handleDeleteSeason(itemId);
        else if (entity === "commercialDate")
          handleDeleteCommercialDate(itemId);
      },
    });
  };

  const commercialDatesColumns = [
    {
      title: t("Common-Name"),
      dataIndex: "name",
      size: 20,
    },
    {
      title: t("Common-StartDate"),
      dataIndex: "startingDate",
      size: 15,
      render: (date: string) => moment(date, "YYYY-MM-DD").format("DD-MM"),
    },
    {
      title: t("Common-EndDate"),
      dataIndex: "endingDate",
      size: 15,
      render: (date: string) => moment(date, "YYYY-MM-DD").format("DD-MM"),
    },
    {
      title: t("Common-Actions"),
      key: "action",
      size: 15,
      render: (text: any, record: any) => (
        <Space size="middle">
          <EditOutlined
            onClick={() => {
              navigate(`/admin/parameters/commercialdates/${record.id}/view`);
            }}
          />
          <DeleteOutlined
            onClick={() => {
              showDeleteConfirm(record.id, "commercialDate");
            }}
          />
        </Space>
      ),
    },
  ];

  const seasonColumns = [
    {
      title: t("Common-Name"),
      dataIndex: "name",
      size: 20,
    },
    {
      title: t("Common-StartDate"),
      dataIndex: "startingDate",
      size: 15,
      render: (date: string) => moment(date, "YYYY-MM-DD").format("DD-MM"),
    },
    {
      title: t("Common-EndDate"),
      dataIndex: "endingDate",
      size: 15,
      render: (date: string) => moment(date, "YYYY-MM-DD").format("DD-MM"),
    },
    {
      title: t("Common-Actions"),
      key: "action",
      size: 15,
      render: (text: any, record: any) => (
        <Space size="middle">
          <EditOutlined
            onClick={() => {
              navigate(`/admin/parameters/seasons/${record.id}/view`);
            }}
          />
          <DeleteOutlined
            onClick={() => {
              showDeleteConfirm(record.id, "season");
            }}
          />
        </Space>
      ),
    },
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-Parameters")}</h1>
      </div>
      <div className={styles.contentWrapper}>
        {/*{hasPermission(privilegesEnum.READ_ARTICLES) && (*/}
        <div className={styles.tableWrapper}>
          <div className={styles.headerTableWrapper}>
            <h1 className={styles.tableTitle}>{t("Common-CommercialDates")}</h1>
            <Button
              onClick={() =>
                navigate("/admin/parameters/commercialdates/create")
              }
              type="primary"
              className={styles.newButton}
              icon={<PlusOutlined />}
            />
          </div>
          <Table
            size="small"
            className={styles.table}
            rowKey={(record) => record.id}
            loading={loadingDates}
            columns={commercialDatesColumns}
            dataSource={commercialDates}
            pagination={{
              total: totalSizeDates,
              showSizeChanger: false,
              onChange: handlePaginationComercialDates,
              current: actualPageDates,
            }}
          />
        </div>
        {/*)}*/}
        <div className={styles.tableWrapper}>
          <div className={styles.headerTableWrapper}>
            <h1 className={styles.tableTitle}>{t("Common-Seasons")}</h1>
            <Button
              onClick={() => navigate("/admin/parameters/seasons/create")}
              type="primary"
              className={styles.newButton}
              icon={<PlusOutlined />}
            />
          </div>
          <Table
            size="small"
            className={styles.table}
            rowKey={(record) => record.id}
            loading={loadingSeasons}
            columns={seasonColumns}
            dataSource={seasons}
            pagination={{
              total: totalSizeSeasons,
              showSizeChanger: false,
              onChange: handlePaginationSeasons,
              current: actualPageSeasons,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Parameters;
