import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  deletePricing,
  getArticlesByName,
  getBranchOfficesFiltered,
  getBrandsFiltered,
  getCategoriesFiltered,
  getPricings,
} from "../../api/Services";
import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";

import {
  Table,
  Button,
  Modal,
  Select,
  Dropdown,
  Menu,
  message,
  Spin,
} from "antd";
import {
  CalendarOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  MoreOutlined,
  PlusOutlined,
  SendOutlined,
} from "@ant-design/icons";

import styles from "./Pricings.module.scss";

const Pricings = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [data, setData] = useState<any[]>([]);
  const [categories, setCategories] = useState<any[]>([]);
  const [brands, setBrands] = useState<any[]>([]);
  const [branchOffices, setBranchOffices] = useState<any[]>([]);
  const [branchOfficeFilterValue, setBranchOfficeFilterValue] =
    useState<number>();
  const [loading, setLoading] = useState(false);
  const [loadingArticles, setLoadingArticles] = useState(false);
  const [totalSize, setTotalSize] = useState(0);
  const [actualPage, setActualPage] = useState(1);
  const [categoryIdFilterValue, setCategoryIdFilterValue] = useState<number>();
  const [brandIdFilterValue, setBrandIdFilterValue] = useState<number>();
  const [articleOptions, setArticleOptions] = useState<any[]>([]);
  const [articleIdFilterValue, setArticleIdFilterValue] = useState("");

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_PRICINGS)) {
      handleFetchPricings();
    }
  }, [
    categoryIdFilterValue,
    brandIdFilterValue,
    branchOfficeFilterValue,
    articleIdFilterValue,
  ]);

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_PRICINGS)) {
      handleSearchBrands("");
      handleSearchCategories("");
      handleSearchBranchOffices("");
    }
  }, []);

  const handleSearchArticles = async (value: string) => {
    if (value.length > 2) {
      setLoadingArticles(true);
      const response = await getArticlesByName(value);
      const options = response.articles.map((item: any) => ({
        value: item.id,
        text: item.name,
      }));
      setArticleOptions(options);
      setLoadingArticles(false);
    } else setArticleOptions([]);
  };
  const handleSearchBranchOffices = async (value: string) => {
    const response = await getBranchOfficesFiltered(0, {
      name: value,
    });
    setBranchOffices(response.branchOffices);
  };

  const handleSearchCategories = async (value: string) => {
    const response = await getCategoriesFiltered(value);
    setCategories(response);
  };

  const handleSearchBrands = async (value: string) => {
    const response = await getBrandsFiltered(value);
    setBrands(response);
  };
  const handleFetchPricings = async (pagination = actualPage) => {
    const response = await getPricings(pagination, {
      categoryId: categoryIdFilterValue?.toString(),
      brandId: brandIdFilterValue,
      branchOfficeId: branchOfficeFilterValue,
      articleId: articleIdFilterValue,
    });

    setData(response.pricings);
    setTotalSize(response.totalElements);
    setLoading(false);
  };

  const getOptions = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {item.name}
        </Select.Option>
      );
    });

    return options;
  };

  const handlePagination = (page: number) => {
    handleFetchPricings(page);
    setActualPage(page);
  };

  const columns = [
    {
      title: t("Common-Id"),
      dataIndex: "id",
    },
    {
      title: t("Common-Name"),
      dataIndex: "name",
    },
    {
      title: t("Common-Description"),
      dataIndex: "description",
    },
    {
      title: t("Common-Brand"),
      dataIndex: "brand",
      render: (brand: any) => brand?.name,
    },
    {
      title: t("Common-Price"),
      dataIndex: "value",
    },
    // {
    //   title: t("Common-PriceDate"),
    //   dataIndex: "startDate",
    //   render: (startDate: string) => startDate?.replace("T", " "),
    // },
    // {
    //   title: t("Common-PriceVigency"),
    //   dataIndex: "endDate",
    //   render: (endDate: string) => endDate?.replace("T", " "),
    // },
    {
      title: t("Common-Status"),
      dataIndex: "status",
      render: (status: any) => status.description_es,
    },
    {
      title: t("Common-Promotion"),
      dataIndex: "promotion",
      render: (promotion: any) => (
        <a href={`/promotions/${promotion?.id}/view`}>{promotion?.name}</a>
      ),
    },
    {
      title: t("Common-Strategy"),
      //IA
    },
    {
      title: t("Common-Currency"),
      dataIndex: "currency",
      render: (currency: any) => currency?.name,
    },
    {
      title: t("Common-Actions"),
      key: "action",
      render: (text: any, record: any) => (
        <Dropdown
          overlay={
            <Menu>
              {hasPermission(privilegesEnum.WRITE_PRICINGS) ? (
                <Menu.Item
                  key="edit"
                  onClick={() => {
                    navigate(`/pricings/${record.id}/view`);
                  }}
                  icon={<SendOutlined />}
                >
                  {t("Common-Edit")}
                </Menu.Item>
              ) : (
                <Menu.Item
                  key="view"
                  onClick={() => {
                    navigate(`/pricings/${record.id}/view`);
                  }}
                  icon={<EyeOutlined />}
                >
                  {t("Common-View")}
                </Menu.Item>
              )}

              {hasPermission(privilegesEnum.DELETE_PRICINGS) && (
                <Menu.Item
                  key="delete"
                  onClick={() => {
                    showDeleteConfirm(record.id);
                  }}
                  icon={<DeleteOutlined />}
                >
                  {t("Common-Delete")}
                </Menu.Item>
              )}
            </Menu>
          }
        >
          <Button
            onClick={(e) => e.preventDefault()}
            className={styles.buttonMore}
          >
            <MoreOutlined className={styles.moreIcon} />
          </Button>
        </Dropdown>
      ),
    },
  ];

  const showDeleteConfirm = (itemId: number) => {
    Modal.confirm({
      title: t("Common-DeleteConfirm"),
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: t("Common-Yes"),
      okType: "danger",
      cancelText: t("Common-No"),
      onOk() {
        handleDelete(itemId);
      },
    });
  };

  const handleDelete = async (pricingId: number) => {
    setLoading(true);
    const deleteStatus = await deletePricing(pricingId);
    if (!deleteStatus) message.error(t("Common-DeleteErrorMessage"));
    else message.success(t("Common-DeleteSuccessMessage"));
    await handleFetchPricings();
    setLoading(false);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-PricingsTitle")}</h1>
        <div className={styles.actionButtonsWrapper}>
          <Button
            onClick={() => navigate("/pricings/calendar")}
            icon={<CalendarOutlined />}
          ></Button>
          <Button
            href="/pricings/create"
            className={styles.newButton}
            type="primary"
            icon={<PlusOutlined />}
          />
        </div>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.tableWrapper}>
          <div className={styles.headerTableWrapper}>
            <div className={styles.filtersWrapper}>
              <div className={styles.filterWrapper}>
                <Select
                  allowClear
                  showSearch
                  className={styles.filterSelect}
                  onSearch={handleSearchCategories}
                  placeholder={t("Common-SearchByCategory")}
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onSelect={(_, option) => {
                    if (option) setCategoryIdFilterValue(option.value);
                  }}
                  onClear={() => setCategoryIdFilterValue(undefined)}
                >
                  {getOptions(categories)}
                </Select>
              </div>
              <div className={styles.filterWrapper}>
                <Select
                  allowClear
                  showSearch
                  className={styles.filterSelect}
                  onSearch={handleSearchBrands}
                  placeholder={t("Common-SearchByBrand")}
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onSelect={(_, option) => {
                    if (option) setBrandIdFilterValue(option.value);
                  }}
                  onClear={() => setBrandIdFilterValue(undefined)}
                >
                  {getOptions(brands)}
                </Select>
              </div>
              <div className={styles.filterWrapper}>
                <Select
                  allowClear
                  className={styles.filterSelect}
                  showSearch
                  onSearch={handleSearchBranchOffices}
                  placeholder={t("Common-SearchByBranchOffice")}
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onSelect={(_, option) => {
                    if (option) setBranchOfficeFilterValue(option.value);
                  }}
                  onClear={() => setBranchOfficeFilterValue(undefined)}
                >
                  {getOptions(branchOffices)}
                </Select>
              </div>
            </div>
            <div className={styles.filtersWrapper}>
              <div className={styles.filterWrapper}></div>
              <div className={styles.filterWrapper}>
                <Select
                  showSearch
                  placeholder={t("Common-SearchByArticle")}
                  allowClear
                  onClear={() => {
                    setArticleIdFilterValue("");
                  }}
                  onSelect={(_, option) => {
                    if (option) {
                      setArticleIdFilterValue(option.value);
                    }
                  }}
                  notFoundContent={
                    loadingArticles ? <Spin size="small" /> : null
                  }
                  className={styles.filterSelect}
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  filterOption={false}
                  onSearch={handleSearchArticles}
                  options={(articleOptions || []).map((d: any) => ({
                    value: d.value,
                    label: d.text,
                  }))}
                />
              </div>
              <div className={styles.filterWrapper}>
                <Select
                  className={styles.filterSelect}
                  showSearch
                  placeholder={"Buscar por grupo de artículos"}
                />
              </div>
            </div>
          </div>

          <Table
            size="small"
            className={styles.table}
            rowKey={(record) => record.id}
            loading={loading}
            columns={columns}
            dataSource={data}
            pagination={{
              total: totalSize,
              showSizeChanger: false,
              onChange: handlePagination,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Pricings;
