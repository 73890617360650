import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  getPricingById,
  getAllEndPricingTypes,
  editPricing,
  getCategoriesFiltered,
  getBranchOfficesFiltered,
  getBrandsFiltered,
  getCurrencies,
} from "../../api/Services";
import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";
import NotFound from "../notfound/NotFound";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

import {
  Form,
  message,
  Select,
  Button,
  Input,
  Spin,
  Typography,
  Table,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import styles from "./ViewPricing.module.scss";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const ViewPricing = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [pricing, setPricing] = useState<any>();
  const [branchOffices, setBranchOffices] = useState<any[]>([]);
  const [categories, setCategories] = useState<any[]>([]);
  const [brands, setBrands] = useState<any[]>([]);
  const [currencies, setCurrencies] = useState<any[]>([]);
  const [endPricingTypes, setEndPricingTypes] = useState<any[]>([]);
  const [articlesSelected, setArticlesSelected] = useState<any[]>([]);

  const [infoForm] = Form.useForm();
  const [behaviourForm] = Form.useForm();
  const [conditionsForm] = Form.useForm();

  const canEdit = hasPermission(privilegesEnum.WRITE_PRICINGS);

  const { id } = useParams();

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_PRICINGS)) {
      handleFetchPricing();
      handleFetchEndPricingTypes();
      handleFetchCurrencies();
      handleSearchBrands("");
    }
  }, []);

  const handleFetchCurrencies = async () => {
    const response = await getCurrencies();
    setCurrencies(response);
  };
  const handleFetchPricing = async () => {
    setLoading(true);

    let response = await getPricingById(id!);
    // let timeLineData = await getPricingTimeLineById(id!);
    setPricing(response);
    setArticlesSelected(response.articles);
    setCategories(response.categories ?? []);
    setBranchOffices(response.branchOffices ?? []);
    handleSearchBrands(response.brand?.name);

    setLoading(false);
  };

  const handleFetchEndPricingTypes = async () => {
    const response = await getAllEndPricingTypes();
    setEndPricingTypes(response);
  };

  const handleSearchCategories = async (value: string) => {
    if (value.length > 2) {
      const response = await getCategoriesFiltered(value);
      setCategories(response);
    } else setCategories([]);
  };

  const handleSearchBrands = async (value: string) => {
    const response = await getBrandsFiltered(value);
    setBrands(response);
  };

  const handleSearchBranchOffices = async (value: string) => {
    if (value.length > 1) {
      const response = await getBranchOfficesFiltered(0, { name: value });
      setBranchOffices(response.branchOffices);
    } else setBranchOffices([]);
  };

  const handleCancelButton = () => {
    infoForm.resetFields();
  };

  const getOptions = (items: any) => {
    const options: any[] = [];

    items.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {item.name}
        </Select.Option>
      );
    });

    return options;
  };

  const getOptionsCategories = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      const parentsString = item?.parents
        ? item.parents.filter(Boolean).join(" - ")
        : "";
      const categoryName = item?.name || "";

      const categoryTree = parentsString
        ? `${parentsString} - ${categoryName}`
        : categoryName;

      options.push(
        <Select.Option value={item.id} key={item.id}>
          {categoryTree}
        </Select.Option>
      );
    });

    return options;
  };

  const getOptionsEndTypes = (items: any) => {
    const options: any[] = [];
    items.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.value} key={item.value}>
          {item.description_es}
        </Select.Option>
      );
    });

    return options;
  };

  const getOptionsDescription = (items: any) => {
    const options: any[] = [];

    items.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {item.description}
        </Select.Option>
      );
    });

    return options;
  };

  const handleEdit = async () => {
    setLoading(true);

    const { name, pricingTypeId } = infoForm.getFieldsValue();

    const behaviour = behaviourForm.getFieldsValue();
    const conditions = conditionsForm.getFieldsValue();

    const { percent, value, branchOfficeIds, categoryIds, allConditions } =
      conditions;

    const articlesIds = articlesSelected.map((article) => article.id);

    const { endPrice, newPrice, date } = behaviour;

    const startDate = date ? date[0]?.format("YYYY-MM-DDTHH:mm:ss") : null;
    const endDate = date ? date[1]?.format("YYYY-MM-DDTHH:mm:ss") : null;

    const pricing = {
      name,
      pricingTypeId,
      percent,
      value,
      articlesIds,
      branchOfficeIds,
      categoryIds,
      endPrice,
      newPrice,
      startDate,
      endDate,
      allConditions,
    };

    const response = await editPricing(parseInt(id!), pricing);
    if (response) {
      message.success(t("Common-EditSuccessMessage"));
      await handleFetchPricing();
    } else message.error(t("Common-EditErrorMessage"));
    setLoading(false);
  };

  const articleColumn = [
    {
      title: t("Common-Article"),
      key: "articleName",
      render: (record: any) => (
        <a href={`/admin/articles/${record.article.id}/view`}>
          {record.article.name}
        </a>
      ),
    },
    {
      title: t("Common-PriceCompetition"),
      dataIndex: "priceCompetition",
    },
    {
      title: t("Common-OptimalPrice"),
      dataIndex: "optimalPrice",
    },
    {
      title: t("Common-Margin"),
      dataIndex: "margin",
    },
    {
      title: t("Common-Cost"),
      dataIndex: "cost",
    },
    {
      title: t("Common-Demand"),
      dataIndex: "demand",
    },
  ];

  const datesColumn = [
    {
      title: t("Common-StartDate"),
      dataIndex: "startDay",
    },
    {
      title: t("Common-EndDate"),
      dataIndex: "endDay",
    },
  ];

  const groupColumn = [
    {
      title: t("Common-ArticleGroup"),
      key: "articleGroupName",
      render: (record: any) => (
        <a href={`/pricings/articlegroups/${record.articleGroup.id}/view`}>
          {record.articleGroup.name}
        </a>
      ),
    },
    {
      title: t("Common-PriceCompetition"),
      dataIndex: "priceCompetition",
    },
    {
      title: t("Common-OptimalPrice"),
      dataIndex: "optimalPrice",
    },
    {
      title: t("Common-Demand"),
      dataIndex: "demand",
    },
  ];

  return loading ? (
    <Spin
      indicator={<LoadingOutlined className="ol-loading-spin-icon" />}
      delay={500}
    />
  ) : !pricing ? (
    <NotFound />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-Pricing")}</h1>
        {hasPermission(privilegesEnum.WRITE_PRICINGS) && (
          <div className={styles.actionButtonsWrapper}>
            {/*<Button*/}
            {/*  onClick={handleEdit}*/}
            {/*  className={styles.saveButton}*/}
            {/*  type="primary"*/}
            {/*>*/}
            {/*  {t("Common-Save")}*/}
            {/*</Button>*/}
            <Button
              onClick={handleCancelButton}
              className={styles.cancelButton}
              type="primary"
            >
              {t("Common-Cancel")}
            </Button>
          </div>
        )}
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.formWrapper}>
          <h1 className={styles.subTitle}>{`${t("Common-PricingEvent")} `}</h1>
          <Form
            name="newPricing"
            layout="vertical"
            initialValues={{ remember: true }}
            autoComplete="off"
            className={styles.newForm}
            form={infoForm}
          >
            <Form.Item
              initialValue={pricing?.name}
              label={t("Common-Name")}
              name="name"
              rules={[
                {
                  required: true,
                  message: t("Common-Name"),
                },
              ]}
            >
              <Input placeholder={t("Common-Name")} disabled={!canEdit} />
            </Form.Item>
            <Form.Item
              initialValue={pricing?.description}
              label={t("Common-Description")}
              name="description"
            >
              <Input
                placeholder={t("Common-Description")}
                disabled={!canEdit}
              />
            </Form.Item>
            <div className={styles.formItemsWrapper}>
              <Form.Item
                label={t("Common-Type")}
                name="endPrice"
                className={styles.doubleItemLine}
                initialValue={pricing?.endPrice.value}
                rules={[
                  {
                    required: true,
                    message: t("Common-Type"),
                  },
                ]}
              >
                <Select
                  allowClear
                  placeholder={t("Common-Type")}
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  disabled={!canEdit}
                >
                  {getOptionsEndTypes(endPricingTypes)}
                </Select>
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.endPrice !== currentValues.endPrice
                }
              >
                {({ getFieldValue }) => {
                  switch (getFieldValue("endPrice")) {
                    case "NEW_PRICE":
                      return (
                        <Form.Item
                          label={t("Common-NewPrice")}
                          name="newPrice"
                          className={styles.doubleItemLine}
                          rules={[
                            {
                              required: true,
                              message: t("Common-NewPrice"),
                            },
                          ]}
                          initialValue={pricing?.newPrice}
                        >
                          <Input
                            placeholder={t("Common-NewPrice")}
                            disabled={!canEdit}
                          />
                        </Form.Item>
                      );
                  }
                }}
              </Form.Item>
            </div>

            <div className={styles.formItemsWrapper}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: t("Common-Price"),
                  },
                ]}
                label={t("Common-Price")}
                name="value"
                className={styles.doubleItemLine}
                initialValue={pricing?.value}
              >
                <Input placeholder={t("Common-Price")} disabled={!canEdit} />
              </Form.Item>
              <Form.Item
                label={t("Common-Currency")}
                className={styles.doubleItemLine}
                name="currencyId"
                initialValue={pricing?.currency?.id}
              >
                <Select
                  allowClear
                  placeholder={t("Common-Currency")}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {getOptions(currencies)}
                </Select>
              </Form.Item>
            </div>
            <div className={styles.formItemsWrapper}>
              <Form.Item
                label={t("Common-Brand")}
                name="brandId"
                className={styles.doubleItemLine}
                initialValue={pricing?.brand?.id}
              >
                <Select
                  allowClear
                  showSearch
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  style={{ width: "100%" }}
                  placeholder={t("Common-SearchByName")}
                  onSearch={handleSearchBrands}
                >
                  {getOptions(brands)}
                </Select>
              </Form.Item>
              <Form.Item
                label={t("Common-BranchOffices")}
                name="branchOfficeIds"
                className={styles.doubleItemLine}
                initialValue={pricing?.branchOffices.map(
                  (branchOffice: any) => branchOffice.id
                )}
              >
                <Select
                  mode="multiple"
                  allowClear
                  onSearch={handleSearchBranchOffices}
                  placeholder={t("Common-SearchByName")}
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  disabled={!canEdit}
                >
                  {getOptions(branchOffices)}
                </Select>
              </Form.Item>
            </div>
            <div className={styles.formItemsWrapper}>
              <Form.Item
                label={t("Common-Categories")}
                name="categoryIds"
                className={styles.doubleItemLine}
                initialValue={pricing?.categories.map(
                  (category: any) => category.id
                )}
              >
                <Select
                  mode="multiple"
                  onSearch={handleSearchCategories}
                  placeholder={t("Common-SearchByName")}
                  allowClear
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  disabled={!canEdit}
                >
                  {getOptionsCategories(categories)}
                </Select>
              </Form.Item>
            </div>
            <div className={styles.formItemsWrapper}>
              <Form.Item
                label={t("Common-Origin")}
                name="origin"
                initialValue={pricing?.pricingOrigin.description_es}
                className={styles.doubleItemLine}
              >
                <Input disabled={true} />
              </Form.Item>
              <Form.Item
                label={t("Common-Status")}
                name="status"
                initialValue={pricing?.status.description_es}
                className={styles.doubleItemLine}
              >
                <Input disabled={true} />
              </Form.Item>
            </div>
          </Form>
        </div>
        <div className={styles.formWrapper}>
          <h1 className={styles.subTitle}>{`${t("Common-Dates")} `}</h1>
          <Table
            size="small"
            className={styles.table}
            loading={loading}
            columns={datesColumn}
            dataSource={pricing?.dates}
          />
        </div>
        <div className={styles.formWrapper}>
          <h1 className={styles.subTitle}>{`${t("Common-Articles")} `}</h1>
          <Table
            size="small"
            className={styles.table}
            loading={loading}
            columns={articleColumn}
            dataSource={pricing?.articles}
          />
        </div>
        <div className={styles.formWrapper}>
          <h1 className={styles.subTitle}>{`${t("Common-ArticleGroups")} `}</h1>
          <Table
            size="small"
            className={styles.table}
            loading={loading}
            columns={groupColumn}
            dataSource={pricing?.articleGroups}
          />
        </div>
      </div>
    </div>
  );
};

export default ViewPricing;
