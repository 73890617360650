import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  getAllEndPricingTypes,
  createPricing,
  getCategoriesFiltered,
  getBranchOfficesFiltered,
  getCurrencies,
  getBrandsFiltered,
  getArticlesFiltered,
  getArticleGroups,
} from "../../api/Services";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar, Line, Doughnut } from "react-chartjs-2";
import {
  Form,
  message,
  Select,
  Button,
  Input,
  Switch,
  DatePicker,
  InputNumber,
  Table,
  Col,
  Row,
  Space,
} from "antd";

import { BusinessUniteContext } from "../../components/laoyut/Layout";
import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";
import {
  DeleteOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import styles from "./NewPricing.module.scss";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const testData = {
  labels: [1, 2, 3],
  datasets: [
    {
      label: "test 1",
      data: [160, 125, 110],
      backgroundColor: ["#FF5454", "#FFB801", "#5DB34C"],
    },
  ],
};

const NewPricing = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { contextValue: businessUnitSelected } =
    useContext(BusinessUniteContext);

  const [loading, setLoading] = useState(false);
  const [branchOffices, setBranchOffices] = useState<any[]>([]);
  const [brands, setBrands] = useState<any[]>([]);
  const [currencies, setCurrencies] = useState<any[]>([]);
  const [categories, setCategories] = useState<any[]>([]);
  const [endPricingTypes, setEndPricingTypes] = useState<any[]>([]);
  const [data, setData] = useState<any[]>([]);
  const [totalSize, setTotalSize] = useState(0);
  const [pricingDates, setPricingDates] = useState([{ key: 0 }]);
  const [articles, setArticles] = useState<any[]>([]);
  const [articleGroups, setArticleGroups] = useState<any[]>([]);

  const [newPricingForm] = Form.useForm();
  const [articlesForm] = Form.useForm();
  const [articleGroupsForm] = Form.useForm();

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_PRICINGS)) {
      handleFetchEndPricingTypes();
      handleFetchCurrencies();
      handleSearchBrands("");
    }
  }, []);

  const addPricingDate = () => {
    setPricingDates([...pricingDates, { key: pricingDates.length }]);
  };

  const removePricingDate = (key: any) => {
    setPricingDates(pricingDates.filter((item) => item.key !== key));
    const currentValues = newPricingForm.getFieldValue("pricingDates") || [];
    newPricingForm.setFieldsValue({
      pricingDates: currentValues.filter((_: any, index: any) => index !== key),
    });
  };

  const handleFetchArticles = async (nameFilterValue: string) => {
    const response = await getArticlesFiltered(0, {
      articleName: nameFilterValue,
    });
    setArticles(response.articles);
  };
  const handleFetchArticleGroups = async (nameFilterValue: string) => {
    const response = await getArticleGroups(0, {
      name: nameFilterValue,
    });
    setArticleGroups(response.articleGroups);
  };

  const handleFetchCurrencies = async () => {
    const response = await getCurrencies();
    setCurrencies(response);
  };

  const handleSearchBranchOffices = async (value: string) => {
    if (businessUnitSelected) {
      const response = await getBranchOfficesFiltered(0, {
        name: value,
        businessUnitId: businessUnitSelected,
      });
      setBranchOffices(response.branchOffices);
    } else setBranchOffices([]);
  };
  const handleSearchBrands = async (value: string) => {
    const response = await getBrandsFiltered(value);
    setBrands(response);
  };
  const handleSearchCategories = async (value: string) => {
    if (value.length > 2) {
      const response = await getCategoriesFiltered(value);
      setCategories(response);
    } else setCategories([]);
  };

  const handleFetchEndPricingTypes = async () => {
    const response = await getAllEndPricingTypes();
    setEndPricingTypes(response);
  };
  const getOptions = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {item.name}
        </Select.Option>
      );
    });

    return options;
  };

  const getOptionsCategories = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      const parentsString = item?.parents
        ? item.parents.filter(Boolean).join(" - ")
        : "";
      const categoryName = item?.name || "";

      const categoryTree = parentsString
        ? `${parentsString} - ${categoryName}`
        : categoryName;

      options.push(
        <Select.Option value={item.id} key={item.id}>
          {categoryTree}
        </Select.Option>
      );
    });

    return options;
  };

  const getOptionsEndTypes = (items: any) => {
    const options: any[] = [];
    items?.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.value} key={item.value}>
          {item.description_es}
        </Select.Option>
      );
    });

    return options;
  };

  const handleCreate = async () => {
    await newPricingForm.validateFields();

    setLoading(true);

    const data = newPricingForm.getFieldsValue();

    const {
      name,
      description,
      currencyId,
      brandId,
      value,
      endPrice,
      newPrice,
      branchOfficeIds,
      categoryIds,
    } = data;

    const articlesIds = articlesForm.getFieldsValue();
    const groupIds = articleGroupsForm.getFieldsValue();

    const dates = data.pricingDates
      .map((range: any) => {
        const startDate = range
          ? range[0]?.startOf("day").format("YYYY-MM-DDTHH:mm:ss")
          : null;
        const endDate = range
          ? range[1]?.endOf("day").format("YYYY-MM-DDTHH:mm:ss")
          : null;

        return { startDate, endDate };
      })
      .filter((date: any) => date.startDate !== null || date.endDate !== null);

    let pricingInfo: any = {
      name,
      description,
      currencyId,
      brandId,
      categoryIds,
      value,
      newPrice,
      endPrice,
      branchOfficeIds,
      articlesIds: articlesIds.articles,
      articleGroups: groupIds.groupArticles,
      dates,
    };

    const response = await createPricing(pricingInfo);

    if (response) {
      message.success(t("Common-CreateSuccessMessage"));
      newPricingForm.resetFields();
      navigate("/pricings");
    } else message.error(t("Common-ErrorMessage"));

    setLoading(false);
  };

  const columns = [
    {
      title: "Id Artículo",
    },
    {
      title: "Nombre Artículo",
    },
    {
      title: "Precio",
    },
    {
      title: "Costo Artículo",
    },
    {
      title: "Margen",
    },
    {
      title: "Fecha Inicio",
    },
    {
      title: "Fecha Fin",
    },
    {
      title: "Razón",
    },
    {
      title: "Usuario",
    },
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-NewPricing")}</h1>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.tableWrapper}>
          <div className={styles.headerTableWrapper}>
            <h1 className={styles.subTitle}>{"Histórico de precios"}</h1>
            <div className={styles.filtersWrapper}>
              <div className={styles.filterWrapper}>
                <Select
                  className={styles.filterSelect}
                  showSearch
                  placeholder={t("Common-SearchByCategory")}
                />
              </div>
              <div className={styles.filterWrapper}>
                <Select
                  className={styles.filterSelect}
                  showSearch
                  placeholder={t("Common-SearchByBrand")}
                />
              </div>
            </div>
          </div>

          <Table
            size="small"
            className={styles.table}
            loading={loading}
            columns={columns}
            dataSource={data}
            pagination={{
              total: totalSize,
              showSizeChanger: false,
              // onChange: handlePagination,
            }}
          />
        </div>
        {/*<div className={styles.formWrapper}>*/}
        {/*  <h1 className={styles.subTitle}>{"Impacto en ventas"}</h1>*/}
        {/*  <Bar data={testData} />*/}
        {/*</div>*/}
        <div className={styles.formWrapper}>
          <div className={styles.header}>
            <h1 className={styles.subTitle}>{"Cambio de precio"}</h1>
            <Button
              onClick={handleCreate}
              className={styles.saveButton}
              type="primary"
              loading={loading}
            >
              {t("Common-Add")}
            </Button>
          </div>
          <Form
            name="conditionForm"
            layout="vertical"
            initialValues={{ remember: true }}
            autoComplete="off"
            className={styles.newForm}
            form={newPricingForm}
          >
            <div className={styles.formItemsWrapper}>
              <Form.Item
                label={t("Common-Name")}
                name="name"
                className={styles.doubleItemLine}
                rules={[
                  {
                    required: true,
                    message: t("Common-Type"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t("Common-Description")}
                name="description"
                className={styles.doubleItemLine}
              >
                <Input />
              </Form.Item>
            </div>
            <div className={styles.formItemsWrapper}>
              <Form.Item
                label={t("Common-Type")}
                name="endPrice"
                className={styles.doubleItemLine}
                rules={[
                  {
                    required: true,
                    message: t("Common-Type"),
                  },
                ]}
              >
                <Select
                  allowClear
                  placeholder={t("Common-Type")}
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {getOptionsEndTypes(endPricingTypes)}
                </Select>
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.endPrice !== currentValues.endPrice
                }
              >
                {({ getFieldValue }) => {
                  switch (getFieldValue("endPrice")) {
                    case "NEW_PRICE":
                      return (
                        <Form.Item
                          label={t("Common-NewPrice")}
                          name="newPrice"
                          className={styles.doubleItemLine}
                          rules={[
                            {
                              required: true,
                              message: t("Common-NewPrice"),
                            },
                          ]}
                        >
                          <Input placeholder={t("Common-NewPrice")} />
                        </Form.Item>
                      );
                  }
                }}
              </Form.Item>
            </div>
            <div className={styles.formItemsWrapper}>
              <Form.Item
                label={t("Common-Price")}
                name="value"
                className={styles.doubleItemLine}
                rules={[
                  {
                    required: true,
                    message: t("Common-Price"),
                  },
                ]}
              >
                <InputNumber className={styles.inputNumber} />
              </Form.Item>
              <Form.Item
                label={t("Common-Currency")}
                className={styles.doubleItemLine}
                name="currencyId"
              >
                <Select
                  allowClear
                  placeholder={t("Common-Currency")}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {getOptions(currencies)}
                </Select>
              </Form.Item>
            </div>
            <div className={styles.formItemsWrapper}>
              <Form.Item
                label={t("Common-BranchOffices")}
                name="branchOfficeIds"
                className={styles.doubleItemLine}
              >
                <Select
                  mode="multiple"
                  allowClear
                  className={styles.branchOfficeSelector}
                  onSearch={handleSearchBranchOffices}
                  placeholder={t("Common-SearchByName")}
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {getOptions(branchOffices)}
                </Select>
              </Form.Item>
              <Form.Item
                label={t("Common-Brand")}
                name="brandId"
                className={styles.doubleItemLine}
              >
                <Select
                  allowClear
                  showSearch
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  style={{ width: "100%" }}
                  placeholder={t("Common-SearchByName")}
                  onSearch={handleSearchBrands}
                >
                  {getOptions(brands)}
                </Select>
              </Form.Item>
            </div>
            <div className={styles.formItemsWrapper}>
              <Form.Item
                label={t("Common-Categories")}
                name="categoryIds"
                className={styles.doubleItemLine}
              >
                <Select
                  mode="multiple"
                  allowClear
                  onSearch={handleSearchCategories}
                  placeholder={t("Common-SearchByName")}
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {getOptionsCategories(categories)}
                </Select>
              </Form.Item>
            </div>
            {pricingDates.map(({ key }, index) => (
              <Row key={key} gutter={16} align="middle">
                <Col span={10}>
                  <Form.Item
                    name={["pricingDates", index]}
                    label={`${t("Common-Range")} ${index + 1}`}
                  >
                    <DatePicker.RangePicker
                      className={styles.dateSelector}
                      format="YYYY-MM-DD"
                      placeholder={[t("Common-StartDate"), t("Common-EndDate")]}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  {pricingDates.length > 1 && (
                    <Button
                      icon={<DeleteOutlined />}
                      onClick={() => removePricingDate(key)}
                    />
                  )}
                </Col>
              </Row>
            ))}

            <Form.Item>
              <Button
                type="dashed"
                icon={<PlusOutlined />}
                onClick={addPricingDate}
                className={styles.addButton}
              >
                {t("Common-Add")}
              </Button>
            </Form.Item>
          </Form>
        </div>
        <div className={styles.articlesWrapper}>
          <h1 className={styles.articlesTitle}>{t("Common-ArticleGroups")}</h1>
          <div className={styles.articlesFormWrapper}>
            <Form
              name="groupArticles"
              layout="vertical"
              initialValues={{ remember: true }}
              autoComplete="off"
              className={styles.articleForm}
              form={articleGroupsForm}
            >
              <Form.List name="groupArticles">
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space
                        key={key}
                        className={styles.contactSpace}
                        align="baseline"
                      >
                        <Form.Item
                          {...restField}
                          label={t("Common-ArticleGroup")}
                          className={styles.articleName}
                          name={[name, "articleGroupId"]}
                          rules={[
                            {
                              required: true,
                              message: t("Common-GroupArticle"),
                            },
                          ]}
                        >
                          <Select
                            className={styles.articleInfoItem}
                            allowClear
                            placeholder={t("Common-SearchByName")}
                            filterOption={(input: string, option: any) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            showSearch
                            style={{ width: "100%" }}
                            onSearch={(value) =>
                              handleFetchArticleGroups(value)
                            }
                          >
                            {getOptions(articleGroups)}
                          </Select>
                        </Form.Item>

                        <Form.Item
                          {...restField}
                          label={t("Common-OptimalPrice")}
                          className={styles.articleInfoItem}
                          name={[name, "optimalPrice"]}
                          rules={[
                            {
                              required: true,
                              message: t("Common-OptimalPrice"),
                            },
                          ]}
                        >
                          <Input
                            className={styles.articleInfoItem}
                            placeholder={t("Common-OptimalPrice")}
                          />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          label={t("Common-PriceCompetition")}
                          className={styles.articleInfoItem}
                          name={[name, "priceCompetition"]}
                          rules={[
                            {
                              required: true,
                              message: t("Common-PriceCompetition"),
                            },
                          ]}
                        >
                          <Input
                            className={styles.articleInfoItem}
                            placeholder={t("Common-PriceCompetition")}
                          />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          label={t("Common-Demand")}
                          className={styles.articleInfoItem}
                          name={[name, "demand"]}
                          rules={[
                            {
                              required: true,
                              message: t("Common-Demand"),
                            },
                          ]}
                        >
                          <Input
                            className={styles.articleInfoItem}
                            placeholder={t("Common-Demand")}
                          />
                        </Form.Item>

                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="primary"
                        className={styles.actionsButton}
                        onClick={() => add()}
                        icon={<PlusOutlined />}
                      />
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Form>
          </div>
        </div>
        <div className={styles.articlesWrapper}>
          <h1 className={styles.articlesTitle}>{t("Common-Articles")}</h1>
          <div className={styles.articlesFormWrapper}>
            <Form
              name="articles"
              layout="vertical"
              initialValues={{ remember: true }}
              autoComplete="off"
              className={styles.articleForm}
              form={articlesForm}
            >
              <Form.List name="articles">
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space
                        key={key}
                        className={styles.contactSpace}
                        align="baseline"
                      >
                        <Form.Item
                          {...restField}
                          label={t("Common-Article")}
                          className={styles.articleName}
                          name={[name, "articleId"]}
                          rules={[
                            {
                              required: true,
                              message: t("Common-Article"),
                            },
                          ]}
                        >
                          <Select
                            className={styles.articleInfoItem}
                            allowClear
                            placeholder={t("Common-SearchByName")}
                            filterOption={(input: string, option: any) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            showSearch
                            style={{ width: "100%" }}
                            onSearch={(value) => handleFetchArticles(value)}
                          >
                            {getOptions(articles)}
                          </Select>
                        </Form.Item>

                        <Form.Item
                          {...restField}
                          label={t("Common-OptimalPrice")}
                          className={styles.articleInfoItem}
                          name={[name, "optimalPrice"]}
                          rules={[
                            {
                              required: true,
                              message: t("Common-OptimalPrice"),
                            },
                          ]}
                        >
                          <Input
                            className={styles.articleInfoItem}
                            placeholder={t("Common-OptimalPrice")}
                          />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          label={t("Common-PriceCompetition")}
                          className={styles.articleInfoItem}
                          name={[name, "priceCompetition"]}
                          rules={[
                            {
                              required: true,
                              message: t("Common-PriceCompetition"),
                            },
                          ]}
                        >
                          <Input
                            className={styles.articleInfoItem}
                            placeholder={t("Common-PriceCompetition")}
                          />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          label={t("Common-Demand")}
                          className={styles.articleInfoItem}
                          name={[name, "demand"]}
                          rules={[
                            {
                              required: true,
                              message: t("Common-Demand"),
                            },
                          ]}
                        >
                          <Input
                            className={styles.articleInfoItem}
                            placeholder={t("Common-Demand")}
                          />
                        </Form.Item>

                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="primary"
                        className={styles.actionsButton}
                        onClick={() => add()}
                        icon={<PlusOutlined />}
                      />
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewPricing;
