import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Table, Input, Select, Tag, Space, Button, Modal, message } from "antd";

import { useNavigate } from "react-router-dom";
import {
  hasPermission,
  privilegesEnum,
} from "../../../helpers/PermissionHelper";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { deleteReward, getRewards } from "../../../api/Services";
import styles from "./Rewards.module.scss";
import moment from "moment";

const Rewards = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalSize, setTotalSize] = useState(0);
  const [actualPage, setActualPage] = useState(1);
  const [nameFilterValue, setNameFilterValue] = useState("");
  const [activeFilterValue, setActiveFilterValue] = useState<
    number | undefined
  >(undefined);

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_VEHICLES)) fetchRewards();
  }, [nameFilterValue, activeFilterValue]);

  const fetchRewards = async (pagination = actualPage) => {
    const response = await getRewards(pagination, {
      name: nameFilterValue,
      active: activeFilterValue,
    });

    setData(response.rewards);
    setTotalSize(response.totalElements);
    setLoading(false);
  };

  const showDeleteConfirm = (id: number) => {
    Modal.confirm({
      title: t("Common-DeleteConfirm"),
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: t("Common-Yes"),
      okType: "danger",
      cancelText: t("Common-No"),
      onOk() {
        handleDelete(id);
      },
    });
  };

  const handleDelete = async (id: number) => {
    setLoading(true);
    const deleteStatus = await deleteReward(id);
    if (!deleteStatus) message.error(t("Common-DeleteErrorMessage"));
    else message.success(t("Common-DeleteSuccessMessage"));
    await fetchRewards();
    setLoading(false);
  };

  const handlePagination = (page: number) => {
    fetchRewards(page);
    setActualPage(page);
  };

  const columns = [
    {
      title: t("Common-Name"),
      dataIndex: "name",
    },
    {
      title: t("Common-Type"),
      dataIndex: "type",
      render: (type: any) => type.name,
    },
    {
      title: t("Common-Status"),
      dataIndex: "status",
      render: (status: boolean) => (
        <>
          <Tag color={status ? "green" : "red"}>
            {status ? t("Common-Active") : t("Common-Inactive")}
          </Tag>
        </>
      ),
    },
    {
      title: t("Common-CreatedDate"),
      dataIndex: "createdAt",
      render: (date: any) =>
        date ? moment(date, "YYYY-MM-DD").format("DD-MM-YYYY") : "",
    },
    {
      title: "Disponibilidad",
    },
    {
      title: t("Common-Actions"),
      key: "action",
      render: (text: any, record: any) => (
        <Space size="middle">
          {hasPermission(privilegesEnum.WRITE_REWARDS) ? (
            <EditOutlined
              onClick={() => {
                navigate(`/fidelity/rewards/${record.id}/view`);
              }}
            />
          ) : (
            <EyeOutlined
              onClick={() => {
                navigate(`/fidelity/rewards/${record.id}/view`);
              }}
            />
          )}
          {hasPermission(privilegesEnum.DELETE_REWARDS) && (
            <DeleteOutlined
              onClick={() => {
                showDeleteConfirm(record.id);
              }}
            />
          )}
        </Space>
      ),
    },
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-Rewards")}</h1>
        <div className={styles.actionButtonsWrapper}>
          <Button
            href="/fidelity/rewards/create"
            className={styles.newButton}
            type="primary"
            icon={<PlusOutlined />}
          />
        </div>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.tableWrapper}>
          <div className={styles.headerTableWrapper}>
            <div className={styles.filtersWrapper}>
              <div className={styles.filterWrapper}>
                <Input
                  placeholder={t("Common-SearchByName")}
                  className={styles.filterInput}
                  onChange={(e) => {
                    setNameFilterValue(e.target.value);
                    setActualPage(1);
                  }}
                  value={nameFilterValue}
                />
              </div>
              <div className={styles.filterWrapper}>
                <Select
                  showSearch
                  allowClear
                  defaultValue={activeFilterValue}
                  className={styles.filterSelect}
                  placeholder={t("Common-SearchByState")}
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onSelect={(_, option) => {
                    if (option) {
                      setActiveFilterValue(option.value);
                      setActualPage(1);
                    }
                  }}
                  onClear={() => {
                    setActiveFilterValue(undefined);
                    setActualPage(1);
                  }}
                >
                  <Select.Option value={0}>
                    {t("Common-Inactive")}
                  </Select.Option>
                  <Select.Option value={1}>{t("Common-Active")}</Select.Option>
                </Select>
              </div>
            </div>
          </div>

          <Table
            size="small"
            className={styles.table}
            loading={loading}
            columns={columns}
            dataSource={data}
            pagination={{
              total: totalSize,
              showSizeChanger: false,
              onChange: handlePagination,
              current: actualPage,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Rewards;
