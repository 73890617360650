import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  Form,
  message,
  Button,
  Input,
  Spin,
  Switch,
  Select,
  InputNumber,
  DatePicker,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import {
  createReward,
  getBrandsFiltered,
  getCategoriesFiltered,
  getClientLevels,
  getClientSegmentsFiltered,
  getRewardTypes,
} from "../../../../api/Services";

import styles from "./NewReward.module.scss";
import {
  hasPermission,
  privilegesEnum,
} from "../../../../helpers/PermissionHelper";
import AddArticleModal from "../../../../components/addarticlemodal/AddArticleModal";

const NewReward = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [brands, setBrands] = useState<any[]>([]);
  const [categories, setCategories] = useState<any[]>([]);
  const [levels, setLevels] = useState<any[]>([]);
  const [rewardTypes, setRewardTypes] = useState<any[]>([]);
  const [clientSegments, setClientSegments] = useState<any[]>([]);
  const [articlesSelected, setArticlesSelected] = useState<any[]>([]);

  const [form] = Form.useForm();

  useEffect(() => {
    if (hasPermission(privilegesEnum.WRITE_REWARDS)) {
      handleSearchBrands("");
      handleSearchCategories("");
      handleSearchLevels("");
      handleSearchClientSegments("");
      handleSearchRewardTypes("");
    }
  }, []);

  const handleSearchBrands = async (value: string) => {
    const response = await getBrandsFiltered(value);
    setBrands(response);
  };
  const handleSearchRewardTypes = async (value: string) => {
    const response = await getRewardTypes(1, { name: value });
    setRewardTypes(response.rewardTypes);
  };
  const handleSearchClientSegments = async (value: string) => {
    const response = await getClientSegmentsFiltered(1, { name: value });
    setClientSegments(response.clientSegments);
  };

  const handleSearchLevels = async (value: string) => {
    const response = await getClientLevels(1, { name: value });
    setLevels(response.clientLevels);
  };
  const handleSearchCategories = async (value: string) => {
    const response = await getCategoriesFiltered(value);
    setCategories(response);
  };

  const handleAddArticle = (articlesToAdd: any) => {
    const newArticles: any[] = [];
    articlesToAdd.forEach((articleToAdd: any) => {
      if (
        articlesSelected.find(
          (article: any) => article.id === articleToAdd.id
        ) === undefined
      )
        newArticles.push(articleToAdd);
    });
    setArticlesSelected([...articlesSelected, ...newArticles]);
  };

  const handleRemoveArticle = (articleRemove: any) => {
    const articlesWithoutRemoved = articlesSelected.filter(
      (article) => article.id !== articleRemove.id
    );

    setArticlesSelected(articlesWithoutRemoved);
  };

  const handleCreate = async () => {
    await form.validateFields().then(async (values) => {
      setLoading(true);
      const {
        name,
        status,
        rewardType,
        value,
        pointsValue,
        description,
        instructionsForUse,
        globalLimit,
        memberLimit,
        memberUseLimit,
        expirationDate,
        validity,
        brandIds,
        categoryIds,
        clientSegmentIds,
        levelIds,
        codeType,
        prefix,
        codeLength,
      } = values;

      const validityStart = validity ? validity[0].format("YYYY-MM-DD") : null;
      const validityEnd = validity ? validity[1].format("YYYY-MM-DD") : null;
      const articleList = articlesSelected.map((article) => article.id);

      let response = await createReward({
        name,
        status,
        rewardType,
        value,
        pointsValue,
        description,
        instructionsForUse,
        globalLimit,
        memberLimit,
        memberUseLimit,
        expirationDate,
        validityStart,
        validityEnd,
        brandIds,
        categoryIds,
        clientSegmentIds,
        levelIds,
        articleIds: articleList,
        articleGroupIds: [],
        codeLength,
        codeType,
        prefix,
      });
      if (response) {
        message.success(t("Common-CreateSuccessMessage"));
        form.resetFields();
        navigate("/fidelity/rewards");
      } else message.error(t("Common-ErrorMessage"));

      setLoading(false);
    });
  };

  const getOptions = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {item.name}
        </Select.Option>
      );
    });

    return options;
  };

  const getOptionsCategories = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      const parentsString = item?.parents
        ? item.parents.filter(Boolean).join(" - ")
        : "";
      const categoryName = item?.name || "";

      const categoryTree = parentsString
        ? `${parentsString} - ${categoryName}`
        : categoryName;

      options.push(
        <Select.Option value={item.id} key={item.id}>
          {categoryTree}
        </Select.Option>
      );
    });

    return options;
  };
  return loading ? (
    <Spin
      indicator={<LoadingOutlined className="ol-loading-spin-icon" />}
      delay={500}
    />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-NewReward")}</h1>
        <Button
          onClick={handleCreate}
          className={styles.saveButton}
          type="primary"
        >
          {t("Common-Add")}
        </Button>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.newWrapper}>
          <Form
            name="newReward"
            layout="vertical"
            initialValues={{ remember: true }}
            form={form}
            autoComplete="off"
            className={styles.formNew}
          >
            <Form.Item
              className={styles.newItem}
              label={t("Common-Name")}
              name="name"
              rules={[
                {
                  required: true,
                  message: t("Common-NameNotEntered"),
                },
              ]}
            >
              <Input placeholder={t("Common-Name")} />
            </Form.Item>
            <Form.Item
              className={styles.newItem}
              label={t("Common-Type")}
              name="rewardType"
              rules={[
                {
                  required: true,
                  message: t("Common-Type"),
                },
              ]}
            >
              <Select
                allowClear
                filterOption={(input: string, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onSearch={handleSearchRewardTypes}
                placeholder={t("Common-SearchByName")}
              >
                {getOptions(rewardTypes)}
              </Select>
            </Form.Item>
            <Form.Item label={t("Common-Active")} name="status">
              <Switch defaultChecked={false} />
            </Form.Item>
            <Form.Item
              className={styles.newItem}
              label={t("Common-Description")}
              name="description"
            >
              <Input placeholder={t("Common-Description")} />
            </Form.Item>
            <Form.Item
              className={styles.newItem}
              label={t("Common-InstructionsForUse")}
              name="instructionsForUse"
            >
              <Input placeholder={t("Common-InstructionsForUse")} />
            </Form.Item>
            <div className={styles.formItemsWrapper}>
              <Form.Item
                className={styles.doubleItemLine}
                label={t("Common-Value")}
                name="value"
              >
                <InputNumber
                  className={styles.inputNumber}
                  min={0}
                  placeholder={t("Common-Value")}
                />
              </Form.Item>
              <Form.Item
                className={styles.doubleItemLine}
                label={t("Common-PointsValue")}
                name="pointsValue"
              >
                <InputNumber
                  className={styles.inputNumber}
                  min={0}
                  placeholder={t("Common-PointsValue")}
                />
              </Form.Item>
            </div>
            <div className={styles.formItemsWrapper}>
              <Form.Item
                className={styles.doubleItemLine}
                label={t("Common-LimitGlobal")}
                name="globalLimit"
              >
                <InputNumber
                  className={styles.inputNumber}
                  min={0}
                  placeholder={t("Common-LimitGlobal")}
                />
              </Form.Item>
              <Form.Item
                className={styles.doubleItemLine}
                label={t("Common-LimitMember")}
                name="memberLimit"
              >
                <InputNumber
                  className={styles.inputNumber}
                  min={0}
                  placeholder={t("Common-LimitMember")}
                />
              </Form.Item>
            </div>
            <div className={styles.formItemsWrapper}>
              <Form.Item
                className={styles.doubleItemLine}
                label={t("Common-LimitMemberUse")}
                name="memberUseLimit"
              >
                <InputNumber
                  className={styles.inputNumber}
                  min={0}
                  placeholder={t("Common-LimitMemberUse")}
                />
              </Form.Item>
              <Form.Item
                className={styles.doubleItemLine}
                label={t("Common-ExpiredDate")}
                name="expirationDate"
              >
                <DatePicker
                  className={styles.dateSelector}
                  showTime={false}
                  format="YYYY-MM-DD"
                />
              </Form.Item>
            </div>
            <Form.Item
              label={t("Common-Valid")}
              name="validity"
              className={styles.doubleItemLine}
            >
              <DatePicker.RangePicker
                className={styles.inputNumber}
                placeholder={[t("Common-ValidFrom"), t("Common-ValidTo")]}
              ></DatePicker.RangePicker>
            </Form.Item>
            <div className={styles.formItemsWrapper}>
              <Form.Item
                label={t("Common-Brands")}
                name="brandIds"
                className={styles.doubleItemLine}
              >
                <Select
                  mode="multiple"
                  allowClear
                  placeholder={t("Common-SearchByName")}
                  onSearch={handleSearchBrands}
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {getOptions(brands)}
                </Select>
              </Form.Item>
              <Form.Item
                className={styles.doubleItemLine}
                label={t("Common-Categories")}
                name="categoryIds"
              >
                <Select
                  mode="multiple"
                  allowClear
                  showSearch
                  onSearch={handleSearchCategories}
                  placeholder={t("Common-SearchByName")}
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  style={{ width: "100%" }}
                >
                  {getOptionsCategories(categories)}
                </Select>
              </Form.Item>
            </div>
            <div className={styles.formItemsWrapper}>
              <Form.Item
                label={t("Common-ClientSegments")}
                name="clientSegmentIds"
                className={styles.doubleItemLine}
              >
                <Select
                  mode="multiple"
                  allowClear
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onSearch={handleSearchClientSegments}
                  placeholder={t("Common-SearchByName")}
                >
                  {getOptions(clientSegments)}
                </Select>
              </Form.Item>
              <Form.Item
                className={styles.doubleItemLine}
                label={t("Common-Level")}
                name="levelIds"
              >
                <Select
                  allowClear
                  mode="multiple"
                  showSearch
                  placeholder={t("Common-SearchByName")}
                  onSearch={handleSearchLevels}
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {getOptions(levels)}
                </Select>
              </Form.Item>
            </div>
            <div className={styles.formItemsWrapper}>
              <Form.Item
                className={styles.doubleItemLine}
                label={t("Common-CodeType")}
                name="codeType"
              >
                <Select>
                  <Select.Option value="ALPHANUMERIC">
                    {t("Common-Alphanumeric")}
                  </Select.Option>
                  <Select.Option value="CHARACTERS">
                    {t("Common-Characters")}
                  </Select.Option>
                  <Select.Option value="NUMBERS">
                    {t("Common-Numbers")}
                  </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                className={styles.doubleItemLine}
                label={t("Common-CodeLength")}
                name="codeLength"
              >
                <InputNumber
                  className={styles.inputNumber}
                  min={0}
                  placeholder={t("Common-CodeLength")}
                />
              </Form.Item>
            </div>
            <Form.Item
              className={styles.doubleItemLine}
              label={t("Common-CodePrefix")}
              name="prefix"
            >
              <Input placeholder={t("Common-CodePrefix")} />
            </Form.Item>
            <AddArticleModal
              selectedArticles={articlesSelected}
              onAddArticle={handleAddArticle}
              onRemoveArticle={handleRemoveArticle}
            />
          </Form>
        </div>
      </div>
    </div>
  );
};

export default NewReward;
