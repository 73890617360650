import { useEffect, useState, useRef } from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import { useTranslation } from "react-i18next";
import {
  getPricingsByMonth,
  getPricingsNoDate,
  schedulePricing,
} from "../../api/Services";
import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";

import {
  Button,
  DatePicker,
  Form,
  message,
  Modal,
  Space,
  Spin,
  Table,
} from "antd";
import {
  CalendarOutlined,
  EditOutlined,
  EyeOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

import styles from "./CalendarPricings.module.scss";

const CalendarPricings = () => {
  const { t } = useTranslation();

  const [data, setData] = useState<any[]>([]);
  const [promosNoDate, setPromosNoDate] = useState<any[]>([]);
  const [totalSize, setTotalSize] = useState(0);
  const [actualPage, setActualPage] = useState(0);
  const [events, setEvents] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [actualDate, setActualDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  const [scheduledForm] = Form.useForm();

  const calendarRef = useRef<FullCalendar>(null!);

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_PRICINGS)) {
      handleFetchPricings();
      handleFetchPricingsNoDate();
    }
  }, []);

  const handleFetchPricings = async () => {
    let newDate = new Date(actualDate);
    newDate = new Date(new Date(newDate).setMonth(newDate.getMonth() + 1));
    const dateStringAfter = newDate.toISOString().split("T")[0];
    newDate = new Date(new Date(newDate).setMonth(newDate.getMonth() - 2));
    const dateStringBefore = newDate.toISOString().split("T")[0];
    await handleFetchPricingsByMonth();
    await handleFetchPricingsByMonth(dateStringAfter);
    await handleFetchPricingsByMonth(dateStringBefore);
  };

  const handleFetchPricingsByMonth = async (month = actualDate) => {
    setLoading(true);
    const response = await getPricingsByMonth(month);
    setData(response);
    const eventsMonth: any = events;
    response.forEach((data: any) => {
      const randomColor =
        "#" + Math.floor(Math.random() * 16777215).toString(16);
      data.dates.forEach((date: any) => {
        //need to add 1 to end date as calendar is exclusive (only for end dates different of start date)
        let endDateString;
        if (date.end) {
          let endDateFormated = new Date(date.end);
          let startDateFormated = new Date(date.start);

          if (
            !(
              startDateFormated.getDay() === endDateFormated.getDay() &&
              startDateFormated.getMonth() === endDateFormated.getMonth()
            )
          )
            endDateFormated = new Date(
              new Date(endDateFormated).setDate(endDateFormated.getDate() + 1)
            );
          endDateString = endDateFormated.toISOString().split("T")[0];
        }
        const alreadyLoad = eventsMonth.find((c: any) => {
          return (
            c.start === date.start?.slice(0, date.start.indexOf("T")) ??
            (date.date && c.title === data.pricing.name)
          );
        });
        if (alreadyLoad === undefined)
          eventsMonth.push({
            url: `${data.pricing.id}/view`,
            title: data.pricing.name,
            groupId: data.pricing.id,
            // start: date.start,
            // end: date.end,
            // allDay: false,
            // backgroundColor: randomColor,
            // borderColor: "#000000",
            start: date.start?.slice(0, date.start.indexOf("T")) ?? date.date,
            end: endDateString ?? "3000-10-01",
          });
      });
    });
    setEvents(eventsMonth);
    setLoading(false);
  };

  const handleFetchPricingsNoDate = async (pagination = actualPage) => {
    const response = await getPricingsNoDate(pagination);
    const allPromotions = response.pricings;
    const pricingsWithKey: any[] = [];

    allPromotions.forEach((pricing: any) => {
      pricing.key = pricing.id;
      pricingsWithKey.push(pricing);
    });
    setPromosNoDate(pricingsWithKey);
    setTotalSize(response.totalElements);
    setLoading(false);
  };

  const handlePagination = (page: number) => {
    handleFetchPricingsNoDate(page);
    setActualPage(page);
  };

  const handleScheduled = async () => {
    const data = scheduledForm.getFieldsValue();
    const { id } = scheduledForm.getFieldsValue();
    const startDate = data.startDate.format("YYYY-MM-DDTHH:mm:ss");
    const endDate = data.endDate?.format("YYYY-MM-DDTHH:mm:ss");

    const response = await schedulePricing(id, startDate, endDate);
    if (response) {
      Modal.destroyAll();
      message.success(t("Common-ProcessSuccessMessage"));
      await handleFetchPricingsNoDate(actualPage);
      await handleFetchPricings();
    } else message.error(t("Common-ErrorMessage"));
  };

  const showScheduledModal = (promotion: any) => {
    Modal.info({
      title: t("Common-Schedule"),
      closable: true,
      maskClosable: true,
      okButtonProps: { style: { display: "none" } },
      width: 500,
      icon: null,
      content: (
        <Form
          name="receivedOrder"
          layout="vertical"
          initialValues={{ remember: true }}
          autoComplete="off"
          form={scheduledForm}
          preserve={false}
        >
          <Form.Item hidden initialValue={promotion.id} name="id" />
          <Form.Item
            label={t("Common-StartDate")}
            name="startDate"
            rules={[
              {
                required: true,
                message: t("Common-StartDate"),
              },
            ]}
          >
            <DatePicker
              className={styles.dateSelector}
              showTime
              format="YYYY-MM-DD HH:mm"
            />
          </Form.Item>
          <Form.Item label={t("Common-EndDate")} name="endDate">
            <DatePicker
              className={styles.dateSelector}
              showTime
              format="YYYY-MM-DD HH:mm"
            />
          </Form.Item>
          <Form.Item>
            <Button
              className={styles.saveButton}
              type="primary"
              htmlType="submit"
              disabled={loading}
              onClick={handleScheduled}
            >
              {loading ? (
                <Spin
                  indicator={
                    <LoadingOutlined className="ol-loading-spin-icon" />
                  }
                  delay={500}
                />
              ) : (
                t("Common-Save")
              )}
            </Button>
          </Form.Item>
        </Form>
      ),
    });
  };

  const columns = [
    {
      title: t("Common-Name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("Common-Value"),
      dataIndex: "value",
      key: "value",
    },
    // {
    //   title: t("Common-Type"),
    //   dataIndex: "pricingType",
    //   key: "pricingType",
    //   render: (pricingType: any) => pricingType?.code,
    // },
    {
      title: t("Common-Actions"),
      key: "action",
      render: (text: any, record: any) => (
        <Space size="middle">
          {hasPermission(privilegesEnum.WRITE_PRICINGS) &&
            record.status.value === "CREATED" && (
              <CalendarOutlined
                onClick={() => {
                  showScheduledModal(record);
                }}
              />
            )}
          {/*{hasPermission(privilegesEnum.WRITE_PRICINGS) &&*/}
          {/*  (record.status.value === "CREATED" ||*/}
          {/*    record.status.value === "SCHEDULED") && (*/}
          {/*    <CloseOutlined*/}
          {/*      onClick={() => {*/}
          {/*        showCancelConfirm(record.id);*/}
          {/*      }}*/}
          {/*    />*/}
          {/*  )}*/}
          {hasPermission(privilegesEnum.WRITE_PRICINGS) ? (
            <EditOutlined
              href={`/pricings/${record.id}/view`}
              onClick={() => {
                window.location.href = `/pricings/${record.id}/view`;
              }}
            />
          ) : (
            <EyeOutlined
              href={`/pricings/${record.id}/view`}
              onClick={() => {
                window.location.href = `/pricings/${record.id}/view`;
              }}
            />
          )}
        </Space>
      ),
    },
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-Pricings")}</h1>
      </div>
      <div className={styles.contentWrapper}>
        {hasPermission(privilegesEnum.READ_PRICINGS) && (
          <>
            <div className={styles.calendarWrapper}>
              {loading ? (
                <Spin
                  indicator={
                    <LoadingOutlined className="ol-loading-spin-icon" />
                  }
                  delay={500}
                />
              ) : (
                <div>
                  <FullCalendar
                    ref={calendarRef}
                    initialDate={actualDate}
                    plugins={[dayGridPlugin, interactionPlugin]}
                    initialView="dayGridMonth"
                    events={events}
                    locale="es"
                    // headerToolbar={false}
                    height="auto"
                    customButtons={{
                      myPrevButton: {
                        icon: "chevron-left",
                        click: function () {
                          let newDate = new Date(actualDate);
                          newDate = new Date(
                            new Date(newDate).setMonth(newDate.getMonth() - 1)
                          );
                          const dateString = newDate
                            .toISOString()
                            .split("T")[0];
                          newDate = new Date(
                            new Date(newDate).setMonth(newDate.getMonth() - 1)
                          );
                          const dateStringBefore = newDate
                            .toISOString()
                            .split("T")[0];
                          newDate = new Date(
                            new Date(newDate).setMonth(newDate.getMonth() + 2)
                          );
                          const dateStringAfter = newDate
                            .toISOString()
                            .split("T")[0];
                          setActualDate(dateString);
                          handleFetchPricingsByMonth(dateString);
                          handleFetchPricingsByMonth(dateStringAfter);
                          handleFetchPricingsByMonth(dateStringBefore);
                          calendarRef.current.getApi().prev();
                        },
                      },
                      myNextButton: {
                        icon: "chevron-right",
                        click: function () {
                          let newDate = new Date(actualDate);
                          newDate = new Date(
                            new Date(newDate).setMonth(newDate.getMonth() + 1)
                          );
                          const dateString = newDate
                            .toISOString()
                            .split("T")[0];
                          newDate = new Date(
                            new Date(newDate).setMonth(newDate.getMonth() + 1)
                          );
                          const dateStringAfter = newDate
                            .toISOString()
                            .split("T")[0];
                          newDate = new Date(
                            new Date(newDate).setMonth(newDate.getMonth() - 2)
                          );
                          const dateStringBefore = newDate
                            .toISOString()
                            .split("T")[0];
                          setActualDate(dateString);
                          handleFetchPricingsByMonth(dateString);
                          handleFetchPricingsByMonth(dateStringBefore);
                          handleFetchPricingsByMonth(dateStringAfter);
                          calendarRef.current.getApi().next();
                        },
                      },
                    }}
                    headerToolbar={{
                      right: "today,myPrevButton,myNextButton",
                    }}
                    buttonText={{
                      today: t("Common-Today"),
                      month: t("Common-Month"),
                      week: t("Common-Week"),
                      day: t("Common-Day"),
                      list: t("Common-List"),
                    }}
                  />
                </div>
              )}
            </div>
            <div className={styles.tableWrapper}>
              <h2 className={styles.subTitle}>{t("Common-PricingsNoDate")}</h2>
              <Table
                size="small"
                className={styles.table}
                loading={loading}
                columns={columns}
                dataSource={promosNoDate}
                pagination={{
                  total: totalSize, showSizeChanger:false,
                  onChange: handlePagination,
                }}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CalendarPricings;
