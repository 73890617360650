const es = {
  "Articles-Articles": "Artículos",
  "Articles-EditArticle": "Editar artículo",
  "Articles-NewArticle": "Nuevo artículo",
  "BusinessRules-Address": "Dirección",
  "BusinessRules-BusinessName": "Razón social",
  "BusinessRules-BusinessUnits": "Unidades de negocio",
  "BusinessRules-Channels": "Canales",
  "BusinessRules-ChannelType": "Tipo",
  "BusinessRules-ChannelTypeNotEntered": "Seleccione un tipo",
  "BusinessRules-CompanyInfoTitle": "Datos de la empresa ",
  "BusinessRules-CreateBusinessUnitSucessMessage":
    "Se ha creado una nueva unidad de negocio.",
  "BusinessRules-CreateChannelSucessMessage": "Se ha creado un nuevo canal.",
  "BusinessRules-Currency": "Moneda",
  "BusinessRules-CurrencyNotEntered": "Seleccione una moneda",
  "BusinessRules-EditBusinessUnit": "Editar unidad de negocio",
  "BusinessRules-EditChannel": "Editar canal",
  "BusinessRules-Language": "Idioma",
  "BusinessRules-LanguageNotEntered": "Seleccione un idioma",
  "BusinessRules-NewBusinessUnit": "Agregar unidad de negocio",
  "BusinessRules-NewChannel": "Nuevo canal",
  "BusinessRules-PreferenceCurrency": "Moneda de preferencia",
  "BusinessRules-Rut": "RUT",
  "BusinessRules-Title": "Configuración de reglas de negocio",
  "BusinessRules-UpdateErrorMessage":
    "Hubo un problema al actualizar, intentelo en un momento",
  "BusinessRules-UpdateSuccessMessage": "Se ha actualizado correctamente.",
  "BusinessRules-UrlNotEntered": "Ingrese una url",
  "Categories-Categories": "Categorías",
  "Categories-CreateCategorySucessMessage": "Se ha creado una nueva categoría.",
  "Categories-Father": "Padre",
  "Categories-New": "Nueva",
  "Categories-NewCategory": "Nueva categoría",
  "Categories-RoundingType": "Tipo de redondeo",
  "Categories-RoundingTypeNotEntered": "Seleccione un tipo de redondeo",
  "Categories-Status": "Estado",
  "ChangePassword-Description":
    "Ingrese una contraseña segura que puedas recordar!",
  "ChangePassword-ResetPassword": "Reiniciar contraseña",
  "ChangePassword-SendErrorMessage":
    "Hubo un problema, intentelo en un momento",
  "ChangePassword-SendSuccesMessage": "Se ha cambiado su contraseña.",
  "ChangePassword-Title": "Crea una nueva contraseña",
  "ChatIa-inputPlaceholder": "Escribi un mensaje",
  "ChatIa-send": "Enviar",
  "CheckEmail-SendAgain": "Enviar correo otra vez",
  "CheckEmail-SendErrorMessage":
    "Hubo un problema al enviar el correo, intentelo en un momento",
  "CheckEmail-SendSuccesMessage":
    "Se ha enviado correctamente. Revise su casilla de correo electronico.",
  "CheckEmail-Title": "Revise su email para continuar con el registro",
  "Common-AboutUs": "Sobre nosotros",
  "Common-AcceptedGoods": "Bienes aceptados",
  "Common-AcceptedPaymentMethod": "Medio de pago aceptado",
  "Common-AcceptedPaymentMethods": "Medios de pagos aceptados",
  "Common-AccountPoints": "Puntos en su cuenta",
  "Common-AccumulateOtherPromos": "Acumula con otras promociones",
  "Common-AccumulatePoints": "Puntos acumulados",
  "Common-AccumulateUnits": "Unidades de acumulación",
  "Common-Accumulation": "Acumulación",
  "Common-AccumulationFactor": "Factor de acumulación",
  "Common-Actions": "Acciones",
  "Common-Active": "Activo",
  "Common-Actives": "Activos",
  "Common-ActualPassword": "Contraseña actual",
  "Common-ActualPrice": "Precio actual",
  "Common-Add": "Agregar",
  "Common-AddArticles": "Agregar artículo",
  "Common-AddCSV": "Subir CSV",
  "Common-AddedSuccessMessage": "Se han agregado correctamente",
  "Common-AddNew": "Agregar nueva",
  "Common-AddPurchaseOrder": "Agregar Orden de Compra",
  "Common-AddRepositionOrder": "Agregar Orden de Reposición",
  "Common-Address": "Dirección",
  "Common-AddSkus": "Agregar sku",
  "Common-AddStock": "Agregar stock",
  "Common-AddStockMovement": "Agregar movimiento de stock",
  "Common-AddSuccessMessage": "Se ha agregado correctamente",
  "Common-Adjustment": "Ajuste",
  "Common-Age": "Edad",
  "Common-AgreedDiscounts": "Descuentos acordados",
  "Common-All": "Todas",
  "Common-AllProducts": "Aplica solo a productos iguales",
  "Common-AllRightsReserved": "Todos los derechos reservados por ",
  "Common-AllSkus": "Todos",
  "Common-Alphanumeric": "Alfanumérico",
  "Common-AmmountSpent": "Cantidad de dinero gastado en un período de tiempo",
  "Common-Analysis": "Análisis",
  "Common-Anually": "Anual",
  "Common-ApplyAllTogether": "Aplican todas juntas",
  "Common-Article": "Artículo",
  "Common-ArticleGroup": "Grupo de Artículos",
  "Common-ArticleGroups": "Grupos de Artículos",
  "Common-Articles": "Artículos",
  "Common-Attribute": "Atributo",
  "Common-Attributes": "Atributos",
  "Common-AvailableStock": "Stock Disponible",
  "Common-AverageTicket": "Ticket promedio",
  "Common-Behaviour": "Comportamiento",
  "Common-BelongsShoppingCenter": "¿Pertenece a centro comercial?",
  "Common-Bin": "Bines",
  "Common-BinLength": "Ingrese una bin de 6 caracteres",
  "Common-BinMinLength": "Debe tener un largo de 6 caracteres",
  "Common-BinRangeMax": "Bines hasta",
  "Common-BinRangeMin": "Bines desde",
  "Common-Birthdate": "Fecha de nacimiento",
  "Common-BirthDate": "Fecha de nacimiento",
  "Common-BirthdayBetween": "Fecha de cumpleaños entre",
  "Common-BranchOffice": "Tienda",
  "Common-BranchOfficeDeposit": "Tienda/Deposito",
  "Common-BranchOffices": "Tiendas",
  "Common-Brand": "Marca",
  "Common-Brands": "Marcas",
  "Common-BusinessUnit": "Unidad de negocio",
  "Common-BuyInSquare": "Compra en plaza",
  "Common-BuyProbability": "Probabilidad de compra",
  "Common-Cancel": "Cancelar",
  "Common-CancelPromotionConfirm":
    "¿Está seguro que quiere cancelar la promocion?",
  "Common-Capacity": "Capacidad",
  "Common-CapacityDispatchUnits": "Capacidad despacho (unidades/día)",
  "Common-CapacityInVolume": "Capacidad en volumen",
  "Common-CapacityMaxInUnits": "Capacidad máxima de carga en unidades",
  "Common-CapacityReception": "Capacidad recepción",
  "Common-CapacityStorageInmt3": "Capacidad de almacenaje en mt3",
  "Common-CapacityStorageInUnits": "Capacidad de almacenaje en unidades",
  "Common-Car": "Auto",
  "Common-Catalogue": "Catalogo",
  "Common-Categories": "Categorías",
  "Common-Category": "Categoría",
  "Common-Cellphone": "Celular",
  "Common-ChangePassword": "Cambiar contraseña",
  "Common-ChangeStatus": "Cambiar estado",
  "Common-Channel": "Canal",
  "Common-Channels": "Canales",
  "Common-Characters": "Caracteres",
  "Common-City": "Ciudad",
  "Common-Client": "Cliente",
  "Common-ClientLevel": "Nivel de cliente",
  "Common-ClientLevels": "Niveles de clientes",
  "Common-ClientRut": "Rut",
  "Common-Clients": "Clientes",
  "Common-ClientSegment": "Segmento de cliente",
  "Common-ClientSegments": "Segmentos de clientes",
  "Common-ClothSizeOp1": "Talle Ropa Opción 1",
  "Common-ClothSizeOp2": "Talle Ropa Opción 2",
  "Common-Cluster": "Cluster",
  "Common-Clusters": "Clusters",
  "Common-Code": "Código",
  "Common-CodeLength": "Largo de código",
  "Common-CodeType": "Tipo de código",
  "Common-CodePrefix": "Prefijo código",
  "Common-Collapse": "Colapsar",
  "Common-Color": "Color",
  "Common-Colour": "Color",
  "Common-Colours": "Colores",
  "Common-Comments": "Comentarios",
  "Common-CommercialDate": "Fecha Comercial",
  "Common-CommercialDates": "Fechas Comerciales",
  "Common-Competitor": "Competidor",
  "Common-Competitors": "Competidores",
  "Common-Complete": "Completar",
  "Common-Completed": "Completada",
  "Common-Conditions": "Condiciones",
  "Common-Confirm": "Confirmar",
  "Common-ConsumedPoints": "Puntos consumidos",
  "Common-Consumption": "Consumo",
  "Common-Contact": "Contacto",
  "Common-ConversionFactor": "Factor de conversión",
  "Common-Cost": "Costo",
  "Common-Country": "País",
  "Common-CreateArticleSucessMessage":
    "Se ha creado un nuevo artículo correctamente.",
  "Common-CreatedDate": "Fecha de creación",
  "Common-CreateErrorMessage":
    "Hubo un problema, intentelo otra vez en un momento.",
  "Common-CreateSuccessMessage": "Se ha creado correctamente.",
  "Common-Currencies": "Monedas",
  "Common-Currency": "Moneda",
  "Common-Curve": "Curva",
  "Common-Curves": "Curvas",
  "Common-Date": "Fecha",
  "Common-Dates": "Fechas",
  "Common-DateLastPurchase": "Fecha de última compra",
  "Common-Day": "Día",
  "Common-Days": "Días",
  "Common-DaysSinceLastPurchase": "Dias desde la última compra",
  "Common-DefaultPrice": "Precio por defecto",
  "Common-Delete": "Eliminar",
  "Common-Demand": "Demanda",
  "Common-DeleteConfirm": "¿Está seguro que quiere eliminar?",
  "Common-DeleteErrorMessage":
    "Hubo un error al eliminar, intentelo más tarde.",
  "Common-DeleteSuccessMessage": "Se ha eliminado correctamente",
  "Common-DeliverTo": "Entrega en",
  "Common-Delivery": "Entrega",
  "Common-DeliveryTerm": "Lead time",
  "Common-Deposit": "Depósito",
  "Common-Deposits": "Depósitos",
  "Common-Description": "Descripción",
  "Common-DescriptionNotEntered": "Descripción",
  "Common-Destination": "Destino",
  "Common-Destiny": "Destino",
  "Common-Discontinue": "Discontinuar",
  "Common-DiscontinueConfirm":
    "¿Está seguro que quiere discontinuar este artículo?",
  "Common-Discontinued": "Discontinuado",
  "Common-DiscountPercent": "Porcentaje de descuento",
  "Common-DiscountType": "Tipo de descuento",
  "Common-DiscountValue": "Valor de descuento",
  "Common-DispatchFrequency": "Frecuencia despacho",
  "Common-Distance": "Distancia",
  "Common-DistributeLines": "Distribuye entre lineas",
  "Common-Distribution": "Distribución",
  "Common-DocumentNumber": "Numero de documento",
  "Common-DocumentType": "Tipo de documento",
  "Common-Download": "Descargar",
  "Common-DownloadCsv": "Descargar CSV",
  "Common-DownloadTemplate": "Descargar Plantilla",
  "Common-Edit": "Editar",
  "Common-EditErrorMessage": "Hubo un error al editar, intentelo más tarde",
  "Common-EditSuccessMessage": "Se ha editado correctamente",
  "Common-Email": "Email",
  "Common-EmailNotEntered": "Ingrese su email",
  "Common-End": "Fin",
  "Common-EndDate": "Fecha de fin",
  "Common-EndDateBirthdate": "Fecha nacimiento hasta",
  "Common-EndDateRegister": "Fecha registro hasta",
  "Common-EndTime": "Hora de fin",
  "Common-EnterABin": "Ingrese el numero de bines de 6 caracteres.",
  "Common-EnterPossibleValues": "Escriba los valores posibles",
  "Common-ErrorMessage": "Ocurrio un error, intentelo mas tarde",
  "Common-ErrorOnExcelFile":
    "Se detectaron los siguientes errores en el archivo seleccionado:",
  "Common-ErrorOnExcelFileLineText":
    "- Hoja: {{sheet}}, Fila {{row}}, Columna {{column}}, error: {{errorMessage}}",
  "Common-EstablishRecurrence": "Establecer recurrencia",
  "Common-EstimatedDeliveryDate": "Fecha estimada",
  "Common-EstimatedStockBreak": "Quiebre estimado",
  "Common-EstimatedDemand": "Demanda estimada",
  "Common-Every": "Cada",
  "Common-exampleURL": "http://ejemplo.com",
  "Common-ExhibitionSpace": "Espacio exhibición",
  "Common-ExpectedResult": "Resultado esperado",
  "Common-Expired": "Expirados",
  "Common-ExpiredDate": "Fecha de vencimiento",
  "Common-ExternalId": "Id externo",
  "Common-FeeMaxRange": "Máximo de cuotas",
  "Common-FeeMinRange": "Mínimo de cuotas",
  "Common-FeeRange": "Rango de cuotas",
  "Common-FeeRangePlaceHolder": "Ej: 1-12",
  "Common-FidelityId": "Id de fidelidad",
  "Common-Flavour": "Sabor",
  "Common-ForgotPassword": "¿Olvidó su contraseña?",
  "Common-ForTotalArticles":
    "Distribuye el total a los artículos que llevan el descuento",
  "Common-FreeShipping": "Envio gratis",
  "Common-Friday": "Viernes",
  "Common-FuelConsumption": "Consumo de combustible por km",
  "Common-FullName": "Nombre completo",
  "Common-FutureExpirations": "Futuras expiraciones",
  "Common-Gender": "Género",
  "Common-Generated": "Generada",
  "Common-GeneratedOrders": "Ordenes a generar",
  "Common-GenerateOrder": "Generar orden",
  "Common-Gift": "Regalo",
  "Common-Go": "Ir",
  "Common-Goals": "Objetivos",
  "Common-GoBack": "Regresar",
  "Common-GroupBySupplier": "Agupar por proveedor",
  "Common-Height": "Altura",
  "Common-HeightWithPackaging": "Altura con empaque",
  "Common-HighPrice": "Aplica a mayor precio",
  "Common-HistoricalStock": "Historial de stock",
  "Common-Hour": "Hora",
  "Common-Id": "Id",
  "Common-Identifier": "Identificador",
  "Common-Import": "Importar",
  "Common-ImportedArticle": "Articulo importado",
  "Common-ImportEntity": "Importar {{entity}}",
  "Common-ImportSuccess": "Se importaron correctamente {{quantity}} filas.",
  "Common-Inactive": "Inactivo",
  "Common-Inactives": "Inactivos",
  "Common-InstructionsForUse": "Instrucciones de uso",
  "Common-InOrders": "En ordenes",
  "Common-InProgress": "En progreso",
  "Common-Interval": "Intervalo",
  "Common-InventoryLevelOptimal": "Nivel de inventario óptimo",
  "Common-IsKit": "¿Es kit?",
  "Common-Iva": "Iva",
  "Common-Kit": "Kit",
  "Common-LastName": "Apellido",
  "Common-Latitude": "Latitud",
  "Common-LeadTime": "Lead Time",
  "Common-Length": "Largo",
  "Common-LengthWithPackaging": "Largo con empaque",
  "Common-Level": "Nivel",
  "Common-LicensePlate": "Matricula",
  "Common-Lines": "Lineas",
  "Common-List": "Lista",
  "Common-LimitGlobal": "Límite global",
  "Common-LimitMember": "Límite por miembro",
  "Common-LimitMemberUse": "Límite de uso por miembro",
  "Common-Locality": "Localidad",
  "Common-Location": "Ubicación",
  "Common-Login": "Login",
  "Common-Longitude": "Longitud",
  "Common-Marketplace": "Marketplace",
  "Common-Margin": "Margen",
  "Common-MaxAge": "Edad máxima",
  "Common-MaximumPrice": "Precio máximo",
  "Common-MaximumStock": "Stock máximo",
  "Common-MaximumTicket": "Monto máximo ticket",
  "Common-MaxMustBeGreaterThanMin":
    "El mínimo de cuotas no puede ser mayor al máximo.",
  "Common-MaxPricePerArticle": "Precio máximo por artículo",
  "Common-Metrics": "Métricas",
  "Common-MinAge": "Edad mínima",
  "Common-MinimumMargin": "Margen mínimo",
  "Common-MinimumOrder": "Pedido mínimo",
  "Common-MinimumPrice": "Precio mínimo",
  "Common-MinimumStock": "Stock mínimo",
  "Common-MinimumTicket": "Monto mínimo ticket",
  "Common-Mode": "Modo",
  "Common-Model": "Modelo",
  "Common-Monday": "Lunes",
  "Common-Month": "Mes",
  "Common-Monthly": "Mensual",
  "Common-MonthsInProgram": "Meses desde que se unio al programa",
  "Common-MoreOptions": "Más opciones",
  "Common-MoreText": "{{quantity}} más...",
  "Common-Motive": "Motivo",
  "Common-Motorcycle": "Moto",
  "Common-mtcube": "mt3",
  "Common-mtsquare": "mt2",
  "Common-MustBeGreaterEqual0": "Debe ser un numero igual o mayor a 0",
  "Common-MyProfile": "Mi Perfil",
  "Common-Name": "Nombre",
  "Common-NameNotEntered": "Debe ingresar un nombre",
  "Common-New": "Nueva",
  "Common-NewAcceptedPaymentMethod": "Nuevo medio de pago aceptado",
  "Common-NewArticle": "Nuevo artículo",
  "Common-NewArticleGroup": "Nuevo grupo de artículos",
  "Common-NewAttribute": "Nuevo atributo",
  "Common-NewBranchOffice": "Nueva tienda",
  "Common-NewBrand": "Nueva marca",
  "Common-NewClient": "Nuevo cliente",
  "Common-NewClientLevel": "Nuevo nivel de cliente",
  "Common-NewClientSegment": "Nuevo segmento",
  "Common-NewCluster": "Nuevo cluster",
  "Common-NewColour": "Nuevo color",
  "Common-NewCommercialDate": "Nueva Fecha Comercial",
  "Common-NewCompetitor": "Nuevo competidor",
  "Common-NewCurve": "Nueva curva",
  "Common-NewDeposit": "Nuevo depósito",
  "Common-NewOrder": "Nueva orden",
  "Common-NewPassword": "Nueva contraseña",
  "Common-NewPrice": "Nuevo precio",
  "Common-NewPricing": "Nuevo pricing",
  "Common-NewPricingType": "Nuevo tipo de pricing",
  "Common-NewPromotion": "Nueva promoción",
  "Common-NewPromotionType": "Nuevo tipo de promoción",
  "Common-NewPurchaseOrder": "Nueva orden de compra",
  "Common-NewPurchaseUnit": "Nueva unidad de compra",
  "Common-NewRepositionOrder": "Nueva orden de reposición",
  "Common-NewRewardType": "Nuevo tipo de recompensa",
  "Common-NewReward": "Nueva recompensa",
  "Common-NewSalePoint": "Nuevo punto de venta",
  "Common-NewSeason": "Nueva Temporada",
  "Common-NewSku": "Nuevo sku",
  "Common-NewSupplierPaymentMethod": "Nuevo método de pago de proveedor",
  "Common-NewTicket": "Nuevo ticket",
  "Common-NewVehicle": "Nuevo vehículo",
  "Common-No": "No",
  "Common-NoArticleApplyingData":
    "No se esta aplicando a ningun artículo actualmente",
  "Common-NotCompleted": "No cumplida",
  "Common-Numbers": "Números",
  "Common-ObligatoryPaymentMethods": "Medios de pagos obligatorios",
  "Common-Observation": "Observación",
  "Common-Observations": "Observaciones",
  "Common-ObtainedResult": "Resultado obtenido",
  "Common-On": "En",
  "Common-OnWay": "En camino",
  "Common-OptimalStock": "Stock óptimo",
  "Common-Order": "Orden",
  "Common-OrdersFulfillment": "Cumplimientos de orden",
  "Common-OrganizationName": "Nombre de organización",
  "Common-OrganizationNameNotEntered": "Ingrese el nombre de su organización",
  "Common-OrganizationNameSpecialCharacters":
    "Ingrese un nombre sin caracteres especiales.",
  "Common-Origin": "Origen",
  "Common-OriginPlace": "Lugar de origen",
  "Common-OriginType": "Procedencia",
  "Common-Parameters": "Parametrizaciones",
  "Common-Partial": "Parcial",
  "Common-Password": "Contraseña",
  "Common-PasswordLength": "Ingrese una contraseña de 8 caracteres",
  "Common-PasswordNotEntered": "Ingrese su contraseña",
  "Common-PaymentMethod": "Método de pago",
  "Common-PaymentMethods": "Metodos de pago",
  "Common-Pending": "Pendiente",
  "Common-Pendings": "Pendientes",
  "Common-Percentage": "Porcentaje",
  "Common-Period": "Periodo",
  "Common-PersonalInfo": "Datos Personales",
  "Common-Photo": "Foto",
  "Common-Place": "Lugar",
  "Common-Points": "Puntos",
  "Common-PointsValue": "Valor en puntos",
  "Common-PointsMovement": "Movimiento de puntos",
  "Common-PointsBenefit": "Puntos del programa de beneficio",
  "Common-PointsLimit": "Límite de puntos del cliente",
  "Common-PointsMovements": "Movimientos de puntos",
  "Common-PointsUntilLimit": "Puntos que faltan hasta llegar al límite",
  "Common-PossibleValues": "Valores posibles",
  "Common-PresentationM3": "Presentación",
  "Common-PresentationType": "Tipo presentación",
  "Common-Price": "Precio",
  "Common-PriceDate": "Fecha del Precio",
  "Common-PriceVigency": "Vigencia del Precio",
  "Common-PriceRestriction": "Restricción de precio",
  "Common-Prices": "Precios",
  "Common-PriceVariation": "Variación de precio",
  "Common-Pricing": "Pricing",
  "Common-PricingEvent": "Evento de pricing",
  "Common-Pricings": "Pricings",
  "Common-PricingsTitle": "Fijación de precios",
  "Common-PricingsNoDate": "Pricings sin agendar",
  "Common-PricingType": "Tipo de pricing",
  "Common-PricingTypes": "Tipos de pricing",
  "Common-Priority": "Prioridad",
  "Common-ProcessSuccessMessage": "Se ha procesado correctamente.",
  "Common-ProgramRegistrationDateBetween":
    "Fecha de registro en el programa entre",
  "Common-Promotion": "Promoción",
  "Common-Promotions": "Promociones",
  "Common-PromotionsUsed": "Promociones usadas",
  "Common-PromotionsNoDate": "Promociones sin agendar",
  "Common-PromotionType": "Tipo de promoción",
  "Common-PromotionTypes": "Tipos de promoción",
  "Common-PurchaseCount": "Cantidad de compras",
  "Common-PurchaseCountDays": "Compras en los últimos X días",
  "Common-PurchaseInfo": "Información de las compras",
  "Common-PurchaseOrder": "Orden de compra ",
  "Common-PurchaseOrders": "Ordenes de compras",
  "Common-PurchaseUnit": "Unidad de compra",
  "Common-PurchaseUnits": "Unidades de compra",
  "Common-Quantity": "Cantidad",
  "Common-QuantityApplications": "Cantidad de aplicaciones",
  "Common-QuantityArticles": "Cantidad Artículos",
  "Common-QuantityForPromotion": "Cantidad artículos a comprar",
  "Common-QuantityFrom": "Cantidad desde",
  "Common-QuantityInDraft": "Cantidad en actual borrador",
  "Common-QuantityMax": "Cantidad maxima a recibir: {{max}}",
  "Common-QuantityPerArticle": "Cantidad por artículo",
  "Common-QuantityPerBranchOffice": "Cantidad por tienda",
  "Common-QuantityPerClient": "Cantidad por cliente",
  "Common-QuantityTo": "Cantidad  hasta",
  "Common-QuantityWithDiscount": "Cantidad con descuento",
  "Common-Range": "Rango",
  "Common-Reason": "Razón",
  "Common-Recommendation": "Recomendación",
  "Common-Receive": "Marcar recepción",
  "Common-ReceivedDate": "Fecha recepción",
  "Common-ReceivedQuantity": "Cantidad recibida",
  "Common-Reception": "Recepción",
  "Common-Recurrence": "Recurrencia",
  "Common-RecurrenceType": "Tipo de recurrencia",
  "Common-Register": "Registrar",
  "Common-RegisterDate": "Fecha de registro",
  "Common-RegisterNow": "O registra una ahora!",
  "Common-Repeat": "Repetir",
  "Common-Replenish": "Reponer",
  "Common-RepositionOrder": "Orden de reposición ",
  "Common-RepositionOrders": "Ordenes de reposición ",
  "Common-RequestDate": "Fecha Solicitud",
  "Common-Requests": "Solicitudes",
  "Common-RestrictedArticles": "Artículos restringidos",
  "Common-RestrictedCategories": "Categorias restringidas",
  "Common-RestrictedPaymentMethods": "Medios de pagos restringidos",
  "Common-Restrictions": "Restricciones",
  "Common-ReturnCount": "Cantidad de devoluciones",
  "Common-ReturnLogin": "Volver al inicio de sesión",
  "Common-ReviewDraft": "Revisión Orden de Resposición",
  "Common-ReviewDraftPurchaseOrder": "Revisión Orden de Compra",
  "Common-RewardTypes": "Tipos de recompensas",
  "Common-RewardType": "Tipo de recompensa",
  "Common-Reward": "Recompensa",
  "Common-Rewards": "Recompensas",
  "Common-Rol": "Rol",
  "Common-Roles": "Roles",
  "Common-RoomSpace": "Capacidad en salón",
  "Common-RoundingType": "Tipo de redondeo",
  "Common-Rule": "Regla",
  "Common-Rule1Description":
    "Crear ordenes de reposición para todas las tiendas con stock 0",
  "Common-Rule2Description":
    "Crear ordenes de reposición para todas las tiendas con stock por debajo del mínimo",
  "Common-RuleQuantity": "Valor para la regla",
  "Common-RulesSuccessMessage": "Operación exitosa",
  "Common-RuleType": "Tipo de regla",
  "Common-RunRules": "Correr reglas",
  "Common-SalePoint": "Punto de venta",
  "Common-SalesPoints": "Puntos de venta",
  "Common-SalesTickets": "Tickets de venta",
  "Common-SalesUnit": "Unidad venta",
  "Common-Saturday": "Sabado",
  "Common-Save": "Guardar",
  "Common-Schedule": "Agendar",
  "Common-Schedules": "Cronogramas",
  "Common-SearchArticleTextPlaceholder": "Buscar artículo por nombre",
  "Common-SearchBranchOfficeDepositTextPlaceholder":
    "Buscar tiendas/depositos por nombre",
  "Common-SearchBranchOfficeDestinyTextPlaceholder":
    "Buscar por tienda destino",
  "Common-SearchBranchOfficeOriginTextPlaceholder":
    "Buscar por tienda origen",
  "Common-SearchBranchOfficeTextPlaceholder": "Buscar tiendas por nombre",
  "Common-SearchBusinessUnitTextPlaceholder":
    "Buscar unidad de negocio por nombre",
  "Common-SearchByArticle": "Buscar por artículo",
  "Common-SearchByAttribute": "Buscar por atributo",
  "Common-SearchByBranchOffice": "Buscar por tienda",
  "Common-SearchByBrand": "Buscar por marca",
  "Common-SearchByCategory": "Buscar por categoría",
  "Common-SearchByCode": "Buscar por código",
  "Common-SearchByEmail": "Buscar por email",
  "Common-SearchByExternalId": "Buscar por id externo",
  "Common-SearchById": "Buscar por id",
  "Common-SearchByLevel": "Buscar por nivel",
  "Common-SearchByName": "Buscar por nombre",
  "Common-SearchByPrivilege": "Buscar por permiso",
  "Common-SearchByRol": "Buscar por rol",
  "Common-SearchBySkuName": "Buscar por nombre de sku",
  "Common-SearchByState": "Buscar por estado",
  "Common-SearchByType": "Buscar por tipo",
  "Common-SearchByValue": "Buscar por valor",
  "Common-SearchDepositTextPlaceholder": "Buscar depositos por nombre",
  "Common-SearchSkuPlaceholder": "Buscar sku por nombre",
  "Common-SearchSuppliersextPlaceholder": "Buscar proveedores por nombre",
  "Common-Season": "Temporada",
  "Common-Seasons": "Temporadas",
  "Common-Segments": "Segmentos",
  "Common-SelectArriveDate": "Selecciona la fecha estimada de arribo:",
  "Common-SelectBranchOfficeError":
    "Debes seleccionar una tienda para buscar.",
  "Common-SelectOneOrMore": "Selecciona uno o más",
  "Common-SelectSupplier": "Seleccione un proveedor",
  "Common-Send": "Enviar",
  "Common-Sent": "Enviada",
  "Common-Sex": "Sexo",
  "Common-ShoeSize": "Talle calzado",
  "Common-ShoppingCenter": "Centro comercial",
  "Common-Size": "Talle",
  "Common-SizeEnd": "Talle fin",
  "Common-SizeStart": "Talle Inicio",
  "Common-Sku": "Sku",
  "Common-Skus": "Skus",
  "Common-Spent": "Gastados",
  "Common-SpentAverage": "Gasto promedio",
  "Common-Start": "Inicio",
  "Common-StartDate": "Fecha de inicio",
  "Common-StartDateBirthdate": "Fecha nacimiento desde",
  "Common-StartDateRegister": "Fecha registro desde",
  "Common-StartTime": "Hora de inicio",
  "Common-Status": "Estado",
  "Common-Stock": "Stock",
  "Common-StockAdjustments": "Ajustes de Stock",
  "Common-StockBetween": "Stock entre",
  "Common-StockBreaks": "Quiebres de stock",
  "Common-StockCurrent": "Stock actual",
  "Common-StockInTransit": "En camino",
  "Common-StockMovements": "Movimientos de Stock",
  "Common-StorageCostPerUnit": "Costo de almacenamiento por unidad",
  "Common-StorageUnit": "Unidad almacenamiento",
  "Common-Strategy": "Estrategia",
  "Common-SubTotal": "Subtotal",
  "Common-SuccessMessage": "Se ha realizado correctamente",
  "Common-Suggested": "Sugerida",
  "Common-Suggestion": "Sugerencia",
  "Common-Sunday": "Domingo",
  "Common-Supplier": "Proveedor",
  "Common-Suppliers": "Proveedores",
  "Common-Suppliers-Payment-Methods": "Métodos de pago de proveedores",
  "Common-Supply": "Abastecer",
  "Common-TargetMargin": "Margen objetivo",
  "Common-Telephone": "Teléfono",
  "Common-ThisOrder": "Esta orden",
  "Common-Thursday": "Jueves",
  "Common-Ticket": "Ticket",
  "Common-TicketAverage": "Promedio ticket",
  "Common-Tickets": "Tickets",
  "Common-TimeLine": "Timeline",
  "Common-Today": "Hoy",
  "Common-Total": "Total",
  "Common-TotalAccumulationUnits": "Total unidades de acumulación",
  "Common-TotalClients": "Total de clientes",
  "Common-TotalFrom": "Total desde",
  "Common-TotalGeneratedPoints": "Total de puntos generados",
  "Common-TotalReceived": "Recepción total",
  "Common-TotalReturns": "Total de devoluciones",
  "Common-TotalSpent": "Total gastado",
  "Common-TotalTo": "Total hasta",
  "Common-TransactionsAverage": "Número promedio de transacciones",
  "Common-Transfer": "Traslado",
  "Common-Transmitter": "Emisor",
  "Common-Truck": "Camion",
  "Common-Tuesday": "Martes",
  "Common-Type": "Tipo",
  "Common-UnitPrice": "Precio unitario",
  "Common-OptimalPrice": "Precio óptimo",
  "Common-PriceCompetition": "Precio competencia",
  "Common-Units": "Unidades",
  "Common-Url": "Url",
  "Common-UrlNotEntered": "Ingrese una url valida",
  "Common-Users": "Usuarios",
  "Common-Valid": "Validez",
  "Common-ValidFrom": "Valido desde",
  "Common-ValidTo": "Valido hasta",
  "Common-Value": "Valor",
  "Common-ValuedInventory": "Inventario Valorizado",
  "Common-Van": "Camioneta",
  "Common-Variety": "Variedad",
  "Common-Vehicle": "Vehículo",
  "Common-Vehicles": "Vehículos",
  "Common-View": "Ver",
  "Common-ViewSummary": "Ver resumen",
  "Common-Volumen": "Volumen",
  "Common-WebDescription": "Descripción Web",
  "Common-Website": "Sitio web",
  "Common-Wednesday": "Miércoles",
  "Common-Week": "Semana",
  "Common-Weekly": "Semanal",
  "Common-Weight": "Peso",
  "Common-WeightWithPackaging": "Peso con empaque",
  "Common-WhoReceived": "Recibido por",
  "Common-Width": "Ancho",
  "Common-WidthWithPackaging": "Ancho con empaque",
  "Common-XDays": "X días",
  "Common-Yes": "Si",
  "EditArticle-Supplier": "Editar proveedor",
  "EditSupplier-Contact": "Editar contacto",
  "Error-CommonError": "Hubo un error, intentalo nuevamente.",
  "Error-IncorrectCredentials": "Las credenciales no son correctas",
  "ForgotPassword-Description":
    "Ingrese su correo electronico y le enviaremos un enlace.",
  "ForgotPassword-SendEmail": "Enviar enlace de acceso",
  "ForgotPassword-SendErrorMessage":
    "Hubo un problema al enviar el correo, intentelo en un momento",
  "ForgotPassword-SendSuccesMessage":
    "Se ha enviado correctamente. Revise su casilla de correo electronico.",
  "ForgotPassword-Title": "¿Olvidó su contraseña?",
  "FormatDate-ddmmyy": "dd/mm/yyyy",
  "LoginForm-NotFoundOrganization": "No existe una organización con ese nombre",
  "NavBar-AcceptedPaymentMethods": "Medios de pagos aceptados",
  "NavBar-Admin": "Admin",
  "NavBar-ArticleGroups": "Grupos de Artículos",
  "NavBar-Articles": "Artículos",
  "NavBar-Assertment": "Assertment",
  "NavBar-Attributes": "Atributos",
  "NavBar-BranchOffices": "Tiendas",
  "NavBar-Brands": "Marcas",
  "NavBar-Calendar": "Calendario",
  "NavBar-Catalogue": "Catalogo",
  "NavBar-Categories": "Categorías",
  "NavBar-Channels": "Canales",
  "NavBar-Clients": "Clientes",
  "NavBar-ClientSegments": "Segmentos de clientes",
  "NavBar-ClientLevels": "Niveles de clientes",
  "NavBar-Clusters": "Clusters",
  "NavBar-Colours": "Colores",
  "NavBar-Company": "Empresa",
  "NavBar-CompanySettings": "Configuración",
  "NavBar-Competitors": "Competidores",
  "NavBar-Curves": "Curvas",
  "NavBar-Dashboard": "Dashboard",
  "NavBar-Deposits": "Depósitos",
  "NavBar-Fidelity": "Fidelidad",
  "NavBar-Home": "Home",
  "NavBar-Logout": "Logout",
  "NavBar-Loyalty": "Fidelidad",
  "NavBar-Management": "Gestion",
  "NavBar-Parameter": "Parametros",
  "NavBar-Parameters": "Parametrizaciones",
  "NavBar-Pricings": "Pricings",
  "NavBar-PricingTypes": "Tipos de pricing",
  "NavBar-Promotions": "Promociones",
  "NavBar-PromotionTypes": "Tipos de promociones",
  "NavBar-PurchaseOrders": "Órdenes de Compra",
  "NavBar-Reports": "Reportes",
  "NavBar-RepositionOrders": "Órdenes de Reposición",
  "NavBar-Requests": "Solicitudes",
  "NavBar-Roles": "Roles",
  "NavBar-SearchInput": "Busca lo que quieras...",
  "NavBar-Shopping": "Compras",
  "NavBar-StockBreaks": "Quiebres de stock",
  "NavBar-Suppliers": "Proveedores",
  "NavBar-Surtido": "Surtido",
  "NavBar-UsersAndRoles": "Usuarios y Roles",
  "NavBar-Vehicles": "Vehículos",
  "NewSupplier-ContactEmail": "Email",
  "NewSupplier-ContactEmailNotEntered": "Ingrese el email de contacto",
  "NewSupplier-ContactName": "Nombre",
  "NewSupplier-ContactNameNotEntered": "Ingrese el nombre de contacto",
  "NewSupplier-ContactNumber": "Número",
  "NewSupplier-ContactNumberNotEntered": "Ingrese el número de contacto",
  "NewSupplier-Contacts": "Contactos",
  "NewSupplier-CreateSupplierSucessMessage":
    "Se ha creado un nuevo proveedor correctamente.",
  "NewSupplier-DeliveryTerm": "Lead Time",
  "NewSupplier-MinimumOrder": "Pedido mínimo",
  "NewSupplier-NewSupplier": "Nuevo proveedor",
  "NewSupplier-PaymentMethod": "Método de pago",
  "NewSupplier-Principal": "Principal",
  "NewSupplier-PurchaseUnit": "Unidad de compra",
  "NewSupplierPaymentMethod-PaymentDeadline": "Plazo de pago",
  "NewSupplierPaymentMethod-PaymentDeadlineError":
    "Debe ingresar un número de días",
  "NotFound-Title": "Esta página no está disponible",
  "Roles-CreateErrorMessage":
    "Hubo un problema, intentelo otra vez en un momento.",
  "Roles-CreateSuccesMessage": "Se ha creado el rol correctamente.",
  "Roles-DeleteConfirm": "¿Está seguro que quiere eliminar el rol?",
  "Roles-DeleteRolErrorMessage":
    "Hubo un error al eliminar, intentelo en un momento.",
  "Roles-DeleteRolSuccessMessage": "Se ha elminado el rol correctamente.",
  "Roles-Description": "Descripción",
  "Roles-EditRol": "Editar rol",
  "Roles-EditRolErrorMessage":
    "Hubo un error al editar, intentelo en un momento.",
  "Roles-EditSuccesMessage": "Se ha editado el rol correctamente.",
  "Roles-NewRol": "Nuevo rol",
  "Roles-Privileges": "Permisos",
  "Roles-PrivilegesNotEntered": "Debe seleccionar al menos un permiso",
  "Roles-Rol": "Rol",
  "Roles-RolesSettings": "Configuración de roles y permisos",
  "Roles-SelectPrivileges": "Selecciona uno o más",
  "Suppliers-CatalogueLink": "Link de catalogo",
  "Suppliers-Payment-Methods-deadlines": "Plazo de pagos (días)",
  "Suppliers-Suppliers": "Proveedores",
  "Users-AddUser": "Agregar nuevo usuario",
  "Users-CreateErrorMessage":
    "Hubo un problema, intentelo otra vez en un momento.",
  "Users-CreateSuccesMessage": "Se ha creado el usuario correctamente.",
  "Users-Edit": "Editar usuario",
  "Users-EditRol": "Editar roles de usuario",
  "Users-NewUser": "Nuevo usuario",
  "Users-Rol": "Rol",
  "Users-RolNotEntered": "Seleccione por lo menos un rol",
  "Users-UsersSettings": "Configuración de usuarios",
  "ValidateEmail-NewPasswordDescription": "Ingrese su nueva contraseña.",
  "ValidateEmail-NewPasswordTitle": "Elija una contraseña",
  "ValidateEmail-UserConfirmed":
    "Usuario confirmado, click aquí para ir al inicio de sesión",
};

export { es };
