import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import NotFound from "../../../notfound/NotFound";
import {
  hasPermission,
  privilegesEnum,
} from "../../../../helpers/PermissionHelper";

import { Button, Form, Input, message, Spin, Switch } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { editRewardType, getRewardTypeById } from "../../../../api/Services";

import styles from "./ViewRewardType.module.scss";

const ViewRewardType = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [rewardType, setRewardType] = useState<any>();

  const [editForm] = Form.useForm();

  const canEdit = hasPermission(privilegesEnum.WRITE_REWARDS);

  const { id } = useParams();

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_REWARDS)) {
      handleFetchRewardType();
    }
  }, []);

  const handleFetchRewardType = async () => {
    let response = await getRewardTypeById(id!);
    setRewardType(response);
    setLoading(false);
  };

  const handleEdit = async () => {
    if (!id) return;

    const { name, status } = editForm.getFieldsValue();
    let response = await editRewardType(id, { name, status });
    if (response) message.success(t("Common-EditSuccessMessage"));
    else message.error(t("Common-EditErrorMessage"));
  };

  const handleCancelButton = () => {
    editForm.resetFields();
  };

  return loading ? (
    <Spin
      indicator={<LoadingOutlined className="ol-loading-spin-icon" />}
      delay={500}
    />
  ) : !rewardType ? (
    <NotFound />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-RewardType")}</h1>
        {hasPermission(privilegesEnum.WRITE_REWARDS) && (
          <div className={styles.actionButtonsWrapper}>
            <Button
              onClick={handleEdit}
              className={styles.saveButton}
              type="primary"
            >
              {t("Common-Save")}
            </Button>
            <Button
              onClick={handleCancelButton}
              className={styles.cancelButton}
              type="primary"
            >
              {t("Common-Cancel")}
            </Button>
          </div>
        )}
      </div>

      <div className={styles.contentWrapper}>
        <div className={styles.infoWrapper}>
          <Form
            name="editRewardType"
            form={editForm}
            autoComplete="off"
            className={styles.newForm}
            preserve={false}
          >
            <Form.Item
              initialValue={rewardType?.name}
              label={t("Common-Name")}
              name="name"
              className={styles.editInfoItem}
              rules={[
                {
                  required: true,
                  message: t("Common-Name"),
                },
              ]}
            >
              <Input disabled={!canEdit} placeholder={t("Common-Name")} />
            </Form.Item>
            <Form.Item
              initialValue={rewardType?.status}
              label={t("Common-Active")}
              name="status"
              rules={[
                {
                  required: true,
                  message: t("Common-Active"),
                },
              ]}
            >
              <Switch defaultChecked={rewardType?.status} />
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ViewRewardType;
