import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Form, message, Button, Input, Spin, Switch } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { createRewardType } from "../../../../api/Services";

import styles from "./NewRewardType.module.scss";

const NewRewardType = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const handleCreate = async () => {
    await form.validateFields().then(async (values) => {
      setLoading(true);
      const { name, status } = values;

      let response = await createRewardType({ name, status });
      if (response) {
        message.success(t("Common-CreateSuccessMessage"));
        form.resetFields();
        navigate("/fidelity/rewardtypes");
      } else message.error(t("Common-ErrorMessage"));

      setLoading(false);
    });
  };

  return loading ? (
    <Spin
      indicator={<LoadingOutlined className="ol-loading-spin-icon" />}
      delay={500}
    />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-NewRewardType")}</h1>
        <Button
          onClick={handleCreate}
          className={styles.saveButton}
          type="primary"
        >
          {t("Common-Add")}
        </Button>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.newWrapper}>
          <Form
            name="newRewardType"
            layout="vertical"
            initialValues={{ remember: true }}
            form={form}
            autoComplete="off"
            className={styles.formNew}
          >
            <Form.Item
              className={styles.newItem}
              label={t("Common-Name")}
              name="name"
              rules={[
                {
                  required: true,
                  message: t("Common-NameNotEntered"),
                },
              ]}
            >
              <Input placeholder={t("Common-Name")} />
            </Form.Item>
            <Form.Item label={t("Common-Active")} name="status">
              <Switch defaultChecked={false} />
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default NewRewardType;
