import { Outlet, useNavigate } from "react-router-dom";
import React, { useMemo, useState, useEffect } from "react";

import NavBar from "../navbar/NavBar";
import {
  Button,
  Drawer,
  Layout as LayoutAntd,
  Menu,
  Space,
  Typography,
} from "antd";
import styles from "./Layout.module.scss";
import logo from "./../../assets/logo.jpg";
import logoSmall from "./../../assets/logo_small.jpeg";
import { useTranslation } from "react-i18next";
import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";
import { MenuOutlined } from "@ant-design/icons";
import { FaRegBuilding } from "react-icons/fa";
import { FiUsers } from "react-icons/fi";
import { GrCatalog } from "react-icons/gr";
import { LiaStoreSolid } from "react-icons/lia";
import { IoSettingsOutline } from "react-icons/io5";
import {
  RxBookmark,
  RxBox,
  RxCardStack,
  RxDashboard,
  RxGroup,
  RxLayers,
  RxListBullet,
  RxMagicWand,
  RxPerson,
  RxSketchLogo,
  RxStar,
} from "react-icons/rx";
import { TbTruckDelivery } from "react-icons/tb";
import { BsCartX } from "react-icons/bs";
import { MdListAlt } from "react-icons/md";
import { SiFig } from "react-icons/si";
import { BiPurchaseTagAlt } from "react-icons/bi";

const { Content, Sider } = LayoutAntd;

export enum SelectedMenuOptions {
  Dashboard = "dashboard",
  Admin = "admin",
  Pricings = "pricings",
  Promotions = "promotions",
  PurchaseOrders = "purchaseorders",
  RepositionOrders = "repositionorders",
  Fidelity = "fidelity",
}

const defaultMenu = () => {
  const { pathname } = window.location;
  const paths = pathname.split("/");
  return paths[1] === ""
    ? SelectedMenuOptions.Dashboard
    : (paths[1] as SelectedMenuOptions);
};

interface BusinessUnitContextType {
  contextValue: string | null;
  updateContextValue: (newValue: string) => void;
}
export const BusinessUniteContext =
  React.createContext<BusinessUnitContextType>({
    contextValue: "",
    updateContextValue: () => {},
  });

export function Layout() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [collapsed, setCollapsed] = useState(false);
  const [selectedNavBarMenu, setSelectedNavBarMenu] =
    useState<SelectedMenuOptions>(defaultMenu);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 720);
  const [businessUnitContextValue, setBusinessUnitContextValue] = useState<
    string | null
  >(null);

  const updateBusinessUnitContextValue = (newValue: string) => {
    setBusinessUnitContextValue(newValue);
  };

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 720) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize);
  });

  const menuPromotions = (
    <>
      <Menu.Item
        key="promotionDashboard"
        // onClick={() => navigate("/fidelity/clients")}
        icon={<RxDashboard />}
      >
        {t("NavBar-Dashboard")}
      </Menu.Item>
      {hasPermission(privilegesEnum.READ_CLIENTS) && (
        <Menu.Item
          key="clients"
          onClick={() => navigate("/promotions/clients")}
          icon={<RxPerson />}
        >
          {t("NavBar-Clients")}
        </Menu.Item>
      )}
      {hasPermission(privilegesEnum.READ_CLIENTS) && (
        <Menu.Item
          key="segments"
          onClick={() => navigate("/promotions/clientsegments")}
          icon={<RxGroup />}
        >
          {t("NavBar-ClientSegments")}
        </Menu.Item>
      )}
      {hasPermission(privilegesEnum.READ_CLIENT_LEVEL) && (
        <Menu.Item
          onClick={() => navigate("/promotions/clientlevels")}
          key="clientLevels"
          icon={<RxLayers />}
        >
          {t("NavBar-ClientLevels")}
        </Menu.Item>
      )}
      {hasPermission(privilegesEnum.READ_ARTICLE_GROUP) && (
        <Menu.Item
          key="promotions-articleGroups"
          onClick={() => navigate("/promotions/articlegroups")}
          icon={<RxBox />}
        >
          {t("NavBar-ArticleGroups")}
        </Menu.Item>
      )}
      {hasPermission(privilegesEnum.READ_PROMOTIONS) && (
        <Menu.Item
          key="promos"
          onClick={() => navigate("/promotions")}
          icon={<RxStar />}
        >
          {t("NavBar-Promotions")}
        </Menu.Item>
      )}

      {hasPermission(privilegesEnum.READ_TICKETS) && (
        <Menu.Item
          key="tickets"
          onClick={() => navigate("/promotions/tickets")}
          icon={<RxCardStack />}
        >
          {t("Common-SalesTickets")}
        </Menu.Item>
      )}
    </>
  );

  const menuFidelity = (
    <>
      <Menu.Item
        key="fidelityDashboard"
        // onClick={() => navigate("/fidelity/clients")}
        icon={<RxDashboard />}
      >
        {t("NavBar-Dashboard")}
      </Menu.Item>
      {hasPermission(privilegesEnum.READ_CLIENTS) && (
        <Menu.Item
          key="clients"
          onClick={() => navigate("/fidelity/clients")}
          icon={<RxPerson />}
        >
          {t("NavBar-Clients")}
        </Menu.Item>
      )}
      {hasPermission(privilegesEnum.READ_CLIENTS) && (
        <Menu.Item
          key="clientsegments"
          onClick={() => navigate("/fidelity/clientsegments")}
          icon={<RxGroup />}
        >
          {t("NavBar-ClientSegments")}
        </Menu.Item>
      )}
      {hasPermission(privilegesEnum.READ_TICKETS) && (
        <Menu.Item
          key="tickets"
          onClick={() => navigate("/fidelity/tickets")}
          icon={<RxCardStack />}
        >
          {t("Common-Tickets")}
        </Menu.Item>
      )}
      <Menu.Item
        key="recommendations"
        onClick={() => navigate("/fidelity/recommendations")}
        icon={<RxMagicWand />}
      >
        {"Recomendaciones"}
      </Menu.Item>
      <Menu.Item
        key="rewardtypes"
        onClick={() => navigate("/fidelity/rewardtypes")}
        icon={<RxListBullet />}
      >
        {"Tipos de recompensa"}
      </Menu.Item>
      <Menu.Item
        key="rewards"
        onClick={() => navigate("/fidelity/rewards")}
        icon={<RxSketchLogo />}
      >
        {"Recompensas"}
      </Menu.Item>
      <Menu.Item
        key="units"
        onClick={() => navigate("/fidelity/units")}
        icon={<RxStar />}
      >
        {"Unidades"}
      </Menu.Item>
    </>
  );

  const menuPurchaseOrders = (
    <>
      <Menu.Item
        className={styles.menuItem}
        key="purchaseordersDashboard"
        onClick={() => navigate("/purchaseorders/dashboard")}
        icon={<RxDashboard />}
      >
        {t("NavBar-Dashboard")}
      </Menu.Item>
      <Menu.Item
        className={styles.menuItem}
        key="purchaseordersCatalogue"
        onClick={() => navigate("/purchaseorders/catalogue")}
        icon={<GrCatalog />}
      >
        {t("NavBar-Catalogue")}
      </Menu.Item>
      <Menu.Item
        className={styles.menuItem}
        key="purchaseordersManagement"
        onClick={() => navigate("/purchaseorders/management")}
        icon={<BiPurchaseTagAlt />}
      >
        {t("NavBar-PurchaseOrders")}
      </Menu.Item>
      <Menu.Item
        className={styles.menuItem}
        key="purchaseordersStockbreaks"
        onClick={() => navigate("/purchaseorders/stockbreaks")}
        icon={<BsCartX />}
      >
        {t("NavBar-StockBreaks")}
      </Menu.Item>
      {/*<Menu.Item*/}
      {/*  className={styles.menuItem}*/}
      {/*  key="purchaseordersReports"*/}
      {/*  onClick={() => navigate("/purchaseorders/reports")}*/}
      {/*  icon={<TbReportAnalytics />}*/}
      {/*>*/}
      {/*  {t("NavBar-Reports")}*/}
      {/*</Menu.Item>*/}
      {/*<Menu.Item*/}
      {/*  className={styles.menuItem}*/}
      {/*  key="purchaseordersRequests"*/}
      {/*  onClick={() => navigate("/purchaseorders/requests")}*/}
      {/*>*/}
      {/*  {t("NavBar-Requests")}*/}
      {/*</Menu.Item>*/}
    </>
  );

  const menuRepositionOrders = (
    <>
      <Menu.Item
        className={styles.menuItem}
        key="dashboard"
        onClick={() => navigate("/repositionorders/dashboard")}
        icon={<RxDashboard />}
      >
        {t("NavBar-Dashboard")}
      </Menu.Item>
      <Menu.Item
        className={styles.menuItem}
        key="catalogue"
        onClick={() => navigate("/repositionorders/catalogue")}
        icon={<GrCatalog />}
      >
        {t("NavBar-Catalogue")}
      </Menu.Item>
      <Menu.Item
        className={styles.menuItem}
        key="management"
        onClick={() => navigate("/repositionorders/management")}
        icon={<TbTruckDelivery />}
      >
        {t("NavBar-RepositionOrders")}
      </Menu.Item>
      <Menu.Item
        className={styles.menuItem}
        key="stockbreaks"
        onClick={() => navigate("/repositionorders/stockbreaks")}
        icon={<BsCartX />}
      >
        {t("NavBar-StockBreaks")}
      </Menu.Item>
      <Menu.Item
        className={styles.menuItem}
        key="requests"
        onClick={() => navigate("/repositionorders/requests")}
        icon={<MdListAlt />}
      >
        {t("NavBar-Requests")}
      </Menu.Item>
      {/*<Menu.Item*/}
      {/*  className={styles.menuItem}*/}
      {/*  key="reports"*/}
      {/*  onClick={() => navigate("/repositionorders/reports")}*/}
      {/*  icon={<TbReportAnalytics />}*/}
      {/*>*/}
      {/*  {t("NavBar-Reports")}*/}
      {/*</Menu.Item>*/}
    </>
  );

  const menuPricing = (
    <>
      <Menu.Item
        key="dashboard"
        // onClick={() => navigate("/fidelity/clients")}
        icon={<RxDashboard />}
      >
        {t("NavBar-Dashboard")}
      </Menu.Item>
      {hasPermission(privilegesEnum.READ_PRICINGS) && (
        <Menu.Item
          key="pricings"
          onClick={() => navigate("/pricings")}
          icon={<RxBookmark />}
        >
          {t("NavBar-Pricings")}
        </Menu.Item>
      )}
      {hasPermission(privilegesEnum.READ_ARTICLE_GROUP) && (
        <Menu.Item
          key="pricings-articleGroups"
          onClick={() => navigate("/pricings/articlegroups")}
          icon={<RxBox />}
        >
          {t("NavBar-ArticleGroups")}
        </Menu.Item>
      )}
    </>
  );

  const menuAdmin = (
    <>
      <Menu.Item
        className={styles.menuItem}
        key="submenu-company"
        icon={<FaRegBuilding />}
        onClick={() => navigate("/admin/businessrules")}
      >
        {t("NavBar-Company")}
      </Menu.Item>

      <Menu.SubMenu
        className={styles.menuItem}
        key="submenu-Parameters"
        icon={<IoSettingsOutline />}
        title={t("NavBar-Parameters")}
      >
        <Menu.Item
          key="parameters"
          onClick={() => navigate("/admin/parameters")}
        >
          {t("NavBar-Parameter")}
        </Menu.Item>
        <Menu.Item key="goals" onClick={() => navigate("/admin/goals")}>
          {t("Common-Goals")}
        </Menu.Item>
        {hasPermission(privilegesEnum.READ_ACCEPTED_PAYMENT_METHODS) && (
          <Menu.Item
            key="paymentmethods"
            onClick={() => navigate("/admin/acceptedpaymentmethods")}
          >
            {t("NavBar-AcceptedPaymentMethods")}
          </Menu.Item>
        )}
      </Menu.SubMenu>

      {(hasPermission(privilegesEnum.READ_USERS) ||
        hasPermission(privilegesEnum.READ_ROLES)) && (
        <Menu.Item
          key="users"
          onClick={() => navigate("/admin/users")}
          icon={<FiUsers />}
        >
          {t("NavBar-UsersAndRoles")}
        </Menu.Item>
      )}

      <Menu.SubMenu
        className={styles.menuItem}
        key="submenu-catalogue"
        icon={<GrCatalog />}
        title={t("NavBar-Catalogue")}
      >
        {hasPermission(privilegesEnum.READ_CATEGORIES) && (
          <Menu.Item
            key="categories"
            onClick={() => navigate("/admin/categories")}
          >
            {t("NavBar-Categories")}
          </Menu.Item>
        )}
        {hasPermission(privilegesEnum.READ_ARTICLES) && (
          <Menu.Item key="articles" onClick={() => navigate("/admin/articles")}>
            {t("NavBar-Articles")}
          </Menu.Item>
        )}
        {hasPermission(privilegesEnum.READ_ATTRIBUTES) && (
          <Menu.Item
            key="attributes"
            onClick={() => navigate("/admin/attributes")}
          >
            {t("NavBar-Attributes")}
          </Menu.Item>
        )}
        {hasPermission(privilegesEnum.READ_COLOURS) && (
          <Menu.Item key="colours" onClick={() => navigate("/admin/colours")}>
            {t("NavBar-Colours")}
          </Menu.Item>
        )}
        {hasPermission(privilegesEnum.READ_SUPPLIERS) && (
          <Menu.Item
            key="suppliers"
            onClick={() => navigate("/admin/suppliers")}
          >
            {t("NavBar-Suppliers")}
          </Menu.Item>
        )}
        {hasPermission(privilegesEnum.READ_BRANDS) && (
          <Menu.Item key="brands" onClick={() => navigate("/admin/brands")}>
            {t("NavBar-Brands")}
          </Menu.Item>
        )}
        {hasPermission(privilegesEnum.READ_CURVES) && (
          <Menu.Item key="curves" onClick={() => navigate("/admin/curves")}>
            {t("NavBar-Curves")}
          </Menu.Item>
        )}
        <Menu.Item
          key="stockmovements"
          onClick={() => navigate("/admin/stockmovements")}
        >
          {t("Common-StockMovements")}
        </Menu.Item>
      </Menu.SubMenu>

      <Menu.SubMenu
        className={styles.menuItem}
        key="submenu-branchoffices"
        // onClick={() => navigate("/admin/branchoffices")}
        icon={<LiaStoreSolid />}
        title={t("NavBar-BranchOffices")}
      >
        {hasPermission(privilegesEnum.READ_BRANCH_OFFICES) && (
          <Menu.Item
            className={styles.menuItem}
            key="branchoffices"
            onClick={() => navigate("/admin/branchoffices")}
          >
            {t("NavBar-BranchOffices")}
          </Menu.Item>
        )}
        {hasPermission(privilegesEnum.READ_DEPOSITS) && (
          <Menu.Item key="deposits" onClick={() => navigate("/admin/deposits")}>
            {t("NavBar-Deposits")}
          </Menu.Item>
        )}
        {hasPermission(privilegesEnum.READ_CLUSTERS) && (
          <Menu.Item
            className={styles.menuItem}
            key="clusters"
            onClick={() => navigate("/admin/clusters")}
          >
            {t("NavBar-Clusters")}
          </Menu.Item>
        )}
        {hasPermission(privilegesEnum.READ_VEHICLES) && (
          <Menu.Item key="vehicles" onClick={() => navigate("/admin/vehicles")}>
            {t("NavBar-Vehicles")}
          </Menu.Item>
        )}
      </Menu.SubMenu>
    </>
  );

  const menuItems = useMemo(() => {
    if (selectedNavBarMenu === SelectedMenuOptions.Admin) return menuAdmin;
    if (selectedNavBarMenu === SelectedMenuOptions.Fidelity)
      return menuFidelity;
    if (selectedNavBarMenu === SelectedMenuOptions.Promotions)
      return menuPromotions;
    if (selectedNavBarMenu === SelectedMenuOptions.Pricings) return menuPricing;
    if (selectedNavBarMenu === SelectedMenuOptions.RepositionOrders)
      return menuRepositionOrders;
    if (selectedNavBarMenu === SelectedMenuOptions.PurchaseOrders)
      return menuPurchaseOrders;
  }, [selectedNavBarMenu]);

  const context: BusinessUnitContextType = {
    contextValue: businessUnitContextValue,
    updateContextValue: updateBusinessUnitContextValue,
  };

  return (
    <BusinessUniteContext.Provider value={context}>
      <LayoutAntd style={{ minHeight: "100vh" }}>
        {!isMobile && (
          <Sider
            className={styles.sider}
            collapsible={
              window.matchMedia("screen and (min-width: 812px)").matches
            }
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
            trigger={
              <div>
                {!collapsed ? (
                  <div className={styles.expandedWrapper}>
                    <SiFig />
                    <Typography.Text>{t("Common-Collapse")}</Typography.Text>
                  </div>
                ) : (
                  <div className={styles.collapsedButton}>
                    <SiFig />
                  </div>
                )}
              </div>
            }
          >
            <Space>
              <img
                className={styles.logo}
                src={collapsed ? logoSmall : logo}
                alt="Logo"
              />
            </Space>

            <Menu mode="inline" className={styles.menu}>
              {menuItems}
            </Menu>
          </Sider>
        )}
        <LayoutAntd className={styles.layoutAntd}>
          <div className={styles.wrap}>
            {isMobile && (
              <>
                <Button
                  icon={<MenuOutlined />}
                  onClick={() => setCollapsed(true)}
                />
                <Drawer
                  visible={collapsed}
                  placement="left"
                  onClose={() => setCollapsed(false)}
                >
                  <Menu mode="inline" className={styles.menu}>
                    {menuItems}
                  </Menu>
                </Drawer>
              </>
            )}
            <NavBar
              onChangeMenu={setSelectedNavBarMenu}
              selectedMenu={selectedNavBarMenu}
            />
          </div>
          <Content>
            <Outlet />
          </Content>
        </LayoutAntd>
      </LayoutAntd>
    </BusinessUniteContext.Provider>
  );
}

export default Layout;
