import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  getAllBranchOfficesTypes,
  createBranchOffice,
  getAllAcceptedPaymentMethods,
  getAllChannelsByBusinessUnits,
  getCategoriesFiltered,
} from "../../api/Services";
import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";

import {
  Form,
  message,
  Select,
  Button,
  Input,
  Switch,
  Spin,
  InputNumber,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { BusinessUniteContext } from "../../components/laoyut/Layout";

import styles from "./NewBranchOffice.module.scss";
import AddArticleModal from "../../components/addarticlemodal/AddArticleModal";

const NewBranchOffice = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { contextValue: businessUnitSelected } =
    useContext(BusinessUniteContext);

  const [loading, setLoading] = useState(false);
  const [belongsShoppingCenter, setBelongsShoppingCenter] = useState(false);
  const [articlesSelected, setArticlesSelected] = useState<any[]>([]);
  const [categories, setCategories] = useState<any[]>([]);
  const [channels, setChannels] = useState<any[]>([]);
  const [types, setTypes] = useState<any[]>([]);
  const [acceptedPaymentMethods, setAcceptedPaymentMethods] = useState<any[]>(
    []
  );
  const [isOnline, setIsOnline] = useState(false);

  const [newBranchOfficeForm] = Form.useForm();

  useEffect(() => {
    if (hasPermission(privilegesEnum.WRITE_BRANCH_OFFICES)) {
      handleFetchBranchOfficesTypes();
      handleFetchAcceptedPaymentMethods();
    }
  }, []);

  useEffect(() => {
    handleFetchChannels();
  }, [businessUnitSelected]);

  const handleFetchChannels = async () => {
    const response = await getAllChannelsByBusinessUnits([
      businessUnitSelected,
    ]);
    newBranchOfficeForm.setFieldsValue({ channelId: null });
    setChannels(response);
  };

  const handleFetchAcceptedPaymentMethods = async () => {
    const response = await getAllAcceptedPaymentMethods();
    setAcceptedPaymentMethods(response);
  };

  const handleFetchBranchOfficesTypes = async () => {
    const response = await getAllBranchOfficesTypes();
    setTypes(response);
  };

  const handleSearchCategories = async (value: string) => {
    if (value.length > 2) {
      const response = await getCategoriesFiltered(value);
      setCategories(response);
    } else setCategories([]);
  };

  const getOptions = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {item.name}
        </Select.Option>
      );
    });

    return options;
  };

  const getOptionsCategories = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      const parentsString = item?.parents
        ? item.parents.filter(Boolean).join(" - ")
        : "";
      const categoryName = item?.name || "";

      const categoryTree = parentsString
        ? `${parentsString} - ${categoryName}`
        : categoryName;

      options.push(
        <Select.Option value={item.id} key={item.id}>
          {categoryTree}
        </Select.Option>
      );
    });

    return options;
  };

  const getChannelOptions = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {`${item.businessUnit.name} - ${item.type.descriptionEs}`}
        </Select.Option>
      );
    });

    return options;
  };

  const getTypesOptions = () => {
    const options: any[] = [];

    types.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.value} key={item.value}>
          {item.descriptionEs}
        </Select.Option>
      );
    });

    return options;
  };

  const handleAddArticle = (articlesToAdd: any) => {
    const newArticles: any[] = [];
    articlesToAdd.forEach((articleToAdd: any) => {
      if (
        articlesSelected.find(
          (article: any) => article.id === articleToAdd.id
        ) === undefined
      )
        newArticles.push(articleToAdd);
    });
    setArticlesSelected([...articlesSelected, ...newArticles]);
  };

  const handleRemoveArticle = (articleRemove: any) => {
    const articlesWithoutRemoved = articlesSelected.filter(
      (article) => article.id !== articleRemove.id
    );

    setArticlesSelected(articlesWithoutRemoved);
  };

  const handleCreateBranchOffice = async () => {
    await newBranchOfficeForm
      .validateFields()
      .then(async (branchOffice) => {
        setLoading(true);

        branchOffice.businessUnitsIds = branchOffice.businessUnitsIds ?? [];
        branchOffice.restrictedCategoriesIds =
          branchOffice.restrictedCategoriesIds ?? [];
        branchOffice.restrictedArticlesIds =
          articlesSelected.map((article) => article.id) ?? [];
        branchOffice.restrictedAcceptedPaymentMethodsIds =
          branchOffice.restrictedAcceptedPaymentMethodsIds ?? [];
        if (businessUnitSelected)
          branchOffice.businessUnitsIds = [businessUnitSelected];
        let response = await createBranchOffice(branchOffice);
        if (response.success) {
          message.success(t("Common-CreateSuccessMessage"));
          navigate(`/admin/branchoffices/${response.branchOffice.id}/view`);
        } else message.error(t("Common-CreateErrorMessage"));

        setLoading(false);
      })
      .catch((exception) => {});
  };

  return loading ? (
    <Spin
      indicator={<LoadingOutlined className="ol-loading-spin-icon" />}
      delay={500}
    />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-NewBranchOffice")}</h1>
        <Button
          onClick={handleCreateBranchOffice}
          className={styles.saveButton}
          type="primary"
        >
          {t("Common-Add")}
        </Button>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.branchOfficeInfoWrapper}>
          <Form
            name="newBranchOffice"
            initialValues={{ remember: true }}
            autoComplete="off"
            className={styles.newForm}
            form={newBranchOfficeForm}
          >
            <div className={styles.itemWrapper}>
              <Form.Item
                label={t("Common-Name")}
                name="name"
                className={styles.formItem}
                rules={[
                  {
                    required: true,
                    message: t("Common-NameNotEntered"),
                  },
                ]}
              >
                <Input placeholder={t("Common-Name")} />
              </Form.Item>

              <Form.Item
                label={t("Common-Type")}
                name="type"
                className={styles.formItem}
                rules={[
                  {
                    required: true,
                    message: t("Common-Type"),
                  },
                ]}
              >
                <Select
                  className={styles.type}
                  allowClear
                  style={{ width: "100%" }}
                  placeholder=""
                  onChange={(value) => {
                    if (value === "ECOMMERCE") setIsOnline(true);
                    else setIsOnline(false);
                  }}
                >
                  {getTypesOptions()}
                </Select>
              </Form.Item>
              <Form.Item
                className={styles.formItem}
                label={t("Common-ExternalId")}
                name="externalId"
              >
                <Input placeholder={t("Common-ExternalId")} />
              </Form.Item>
            </div>
            <div className={styles.itemWrapper}>
              <Form.Item
                label={t("Common-RoomSpace")}
                name="roomCapacity"
                className={styles.formItem}
              >
                <InputNumber
                  placeholder={t("Common-RoomSpace")}
                  addonAfter={t("Common-Units")}
                  className={styles.inputNumber}
                />
              </Form.Item>
              <Form.Item
                label={t("Common-PriceVariation")}
                name="priceVariation"
                className={styles.formItem}
              >
                <Input placeholder={t("Common-PriceVariation")} />
              </Form.Item>
            </div>
            <div className={styles.itemWrapperException}>
              <Form.Item
                label={t("Common-PriceRestriction")}
                name="priceRestriction"
                className={styles.formItem}
              >
                <Input placeholder={t("Common-PriceRestriction")} />
              </Form.Item>
              <Form.Item
                className={styles.formItem}
                label={t("Common-StorageCostPerUnit")}
                name="storageCostPerUnit"
              >
                <InputNumber
                  placeholder={t("Common-StorageCostPerUnit")}
                  className={styles.inputNumber}
                />
              </Form.Item>
              <Form.Item
                label={t("Common-BelongsShoppingCenter")}
                name="belongsShoppingCenter"
                initialValue={belongsShoppingCenter}
                className={styles.formItem}
                rules={[
                  {
                    required: true,
                    message: t("Common-BelongsShoppingCenter"),
                  },
                ]}
              >
                <Switch
                  defaultChecked={belongsShoppingCenter}
                  onChange={setBelongsShoppingCenter}
                />
              </Form.Item>
              {belongsShoppingCenter && (
                <Form.Item
                  label={t("Common-ShoppingCenter")}
                  name="shoppingCenter"
                  className={styles.formItem}
                >
                  <Input placeholder={t("Common-ShoppingCenter")} />
                </Form.Item>
              )}
            </div>
            <div className={styles.itemWrapper}>
              <Form.Item
                label={t("Common-MinimumStock")}
                name="minimumStock"
                className={styles.formItem}
              >
                <InputNumber
                  placeholder={t("Common-MinimumStock")}
                  className={styles.inputNumber}
                />
              </Form.Item>
              <Form.Item
                label={t("Common-MaximumStock")}
                name="maximumStock"
                className={styles.formItem}
              >
                <InputNumber
                  placeholder={t("Common-MaximumStock")}
                  className={styles.inputNumber}
                />
              </Form.Item>
              <Form.Item
                label={t("Common-Location")}
                name="location"
                className={styles.formItem}
              >
                <Input placeholder={t("Common-Location")} />
              </Form.Item>
            </div>
            <div className={styles.itemWrapper}>
              <Form.Item
                label={t("Common-Address")}
                name="address"
                className={styles.formItem}
              >
                <Input placeholder={t("Common-Address")} />
              </Form.Item>
              <Form.Item
                label={t("Common-Latitude")}
                name="latitude"
                className={styles.formItem}
              >
                <InputNumber
                  className={styles.inputNumber}
                  placeholder={t("Common-Latitude")}
                />
              </Form.Item>
              <Form.Item
                label={t("Common-Longitude")}
                name="longitude"
                className={styles.formItem}
              >
                <InputNumber
                  className={styles.inputNumber}
                  placeholder={t("Common-Longitude")}
                />
              </Form.Item>
            </div>
            {isOnline && (
              <div className={styles.itemWrapper}>
                <Form.Item
                  label={t("Common-Url")}
                  name="url"
                  className={styles.formItem}
                  rules={[
                    {
                      type: "url",
                      message: t("Common-Url"),
                    },
                  ]}
                >
                  <Input placeholder={t("Common-exampleURL")} />
                </Form.Item>

                <Form.Item
                  label={t("Common-Marketplace")}
                  name="marketplace"
                  className={styles.formItem}
                >
                  <Input placeholder={t("Common-Marketplace")} />
                </Form.Item>
              </div>
            )}
            <div className={styles.itemWrapper}>
              <Form.Item
                label={t("Common-Channel")}
                name="channelId"
                className={styles.formItem}
              >
                <Select
                  allowClear
                  placeholder={t("Common-Channel")}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {getChannelOptions(channels)}
                </Select>
              </Form.Item>
              <Form.Item
                label={t("Common-RestrictedCategories")}
                name="restrictedCategoriesIds"
                className={styles.formItem}
              >
                <Select
                  className={styles.multipleSelect}
                  onSearch={handleSearchCategories}
                  placeholder={t("Common-SearchByName")}
                  mode="multiple"
                  allowClear
                  style={{ width: "100%" }}
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {getOptionsCategories(categories)}
                </Select>
              </Form.Item>
              <Form.Item
                label={t("Common-RestrictedPaymentMethods")}
                name="restrictedAcceptedPaymentMethodsIds"
                className={styles.formItem}
              >
                <Select
                  className={styles.multipleSelect}
                  mode="multiple"
                  allowClear
                  style={{ width: "100%" }}
                  placeholder=""
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {getOptions(acceptedPaymentMethods)}
                </Select>
              </Form.Item>
            </div>
            <AddArticleModal
              title={t("Common-RestrictedArticles")}
              selectedArticles={articlesSelected}
              onAddArticle={handleAddArticle}
              onRemoveArticle={handleRemoveArticle}
            />
          </Form>
        </div>
      </div>
    </div>
  );
};

export default NewBranchOffice;
