import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import NotFound from "../../../notfound/NotFound";
import {
  hasPermission,
  privilegesEnum,
} from "../../../../helpers/PermissionHelper";

import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Select,
  Spin,
  Switch,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import styles from "./ViewReward.module.scss";
import {
  editReward,
  getBrandsFiltered,
  getCategoriesFiltered,
  getClientLevels,
  getClientSegmentsFiltered,
  getRewardById,
  getRewardTypes,
} from "../../../../api/Services";
import moment from "moment";

const ViewReward = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [reward, setReward] = useState<any>();
  const [rewardTypes, setRewardTypes] = useState<any[]>([]);
  const [brands, setBrands] = useState<any[]>([]);
  const [categories, setCategories] = useState<any[]>([]);
  const [levels, setLevels] = useState<any[]>([]);
  const [clientSegments, setClientSegments] = useState<any[]>([]);
  const [articlesSelected, setArticlesSelected] = useState<any[]>([]);

  const [editForm] = Form.useForm();

  const canEdit = hasPermission(privilegesEnum.WRITE_REWARDS);

  const { id } = useParams();

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_REWARDS)) {
      handleFetchReward();
    }
  }, []);

  const handleFetchReward = async () => {
    let response = await getRewardById(id!);
    setReward(response);
    setBrands(response.brands);
    setCategories(response.categories);
    setClientSegments(response.clientSegments);
    setLevels(response.levels);
    handleSearchRewardTypes(response.type.name);
    setLoading(false);
  };

  const handleSearchBrands = async (value: string) => {
    const response = await getBrandsFiltered(value);
    setBrands(response);
  };

  const handleSearchClientSegments = async (value: string) => {
    const response = await getClientSegmentsFiltered(1, { name: value });
    setClientSegments(response.clientSegments);
  };

  const handleSearchLevels = async (value: string) => {
    const response = await getClientLevels(1, { name: value });
    setLevels(response.clientLevels);
  };
  const handleSearchCategories = async (value: string) => {
    const response = await getCategoriesFiltered(value);
    setCategories(response);
  };

  const handleSearchRewardTypes = async (value: string) => {
    const response = await getRewardTypes(1, { name: value });
    setRewardTypes(response.rewardTypes);
  };

  const getOptions = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {item.name}
        </Select.Option>
      );
    });

    return options;
  };

  const getOptionsCategories = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      const parentsString = item?.parents
        ? item.parents.filter(Boolean).join(" - ")
        : "";
      const categoryName = item?.name || "";

      const categoryTree = parentsString
        ? `${parentsString} - ${categoryName}`
        : categoryName;

      options.push(
        <Select.Option value={item.id} key={item.id}>
          {categoryTree}
        </Select.Option>
      );
    });

    return options;
  };

  const handleEdit = async () => {
    if (!id) return;

    const {
      name,
      status,
      rewardType,
      value,
      pointsValue,
      description,
      instructionsForUse,
      globalLimit,
      memberLimit,
      memberUseLimit,
      expirationDate,
      validity,
      brandIds,
      categoryIds,
      clientSegmentIds,
      levelIds,
      codeType,
      prefix,
      codeLength,
    } = editForm.getFieldsValue();
    const validityStart = validity ? validity[0].format("YYYY-MM-DD") : null;
    const validityEnd = validity ? validity[1].format("YYYY-MM-DD") : null;
    const expirationDateFormatted = expirationDate
      ? expirationDate.format("YYYY-MM-DD")
      : null;

    let response = await editReward(id, {
      name,
      status,
      rewardType,
      value,
      pointsValue,
      description,
      instructionsForUse,
      globalLimit,
      memberLimit,
      memberUseLimit,
      expirationDate: expirationDateFormatted,
      validityStart,
      validityEnd,
      brandIds,
      categoryIds,
      clientSegmentIds,
      levelIds,
      articleIds: reward.articles.map((art: any) => art.id),
      articleGroupIds: [],
      codeLength,
      codeType,
      prefix,
    });
    if (response) message.success(t("Common-EditSuccessMessage"));
    else message.error(t("Common-EditErrorMessage"));
  };

  const handleCancelButton = () => {
    editForm.resetFields();
  };

  return loading ? (
    <Spin
      indicator={<LoadingOutlined className="ol-loading-spin-icon" />}
      delay={500}
    />
  ) : !reward ? (
    <NotFound />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-Reward")}</h1>
        {hasPermission(privilegesEnum.WRITE_REWARDS) && (
          <div className={styles.actionButtonsWrapper}>
            <Button
              onClick={handleEdit}
              className={styles.saveButton}
              type="primary"
            >
              {t("Common-Save")}
            </Button>
            <Button
              onClick={handleCancelButton}
              className={styles.cancelButton}
              type="primary"
            >
              {t("Common-Cancel")}
            </Button>
          </div>
        )}
      </div>

      <div className={styles.contentWrapper}>
        <div className={styles.infoWrapper}>
          <Form
            name="editreward"
            form={editForm}
            layout="vertical"
            autoComplete="off"
            className={styles.newForm}
            preserve={false}
          >
            <Form.Item
              initialValue={reward?.name}
              label={t("Common-Name")}
              name="name"
              className={styles.newItem}
              rules={[
                {
                  required: true,
                  message: t("Common-Name"),
                },
              ]}
            >
              <Input disabled={!canEdit} placeholder={t("Common-Name")} />
            </Form.Item>
            <Form.Item
              className={styles.newItem}
              label={t("Common-Type")}
              name="rewardType"
              initialValue={reward?.type?.id}
              rules={[
                {
                  required: true,
                  message: t("Common-Type"),
                },
              ]}
            >
              <Select
                allowClear
                filterOption={(input: string, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onSearch={handleSearchRewardTypes}
                placeholder={t("Common-SearchByName")}
              >
                {getOptions(rewardTypes)}
              </Select>
            </Form.Item>
            <Form.Item
              className={styles.newItem}
              label={t("Common-Active")}
              initialValue={reward?.status}
              name="status"
            >
              <Switch defaultChecked={reward?.status} />
            </Form.Item>
            <Form.Item
              className={styles.newItem}
              label={t("Common-Description")}
              name="description"
              initialValue={reward?.description}
            >
              <Input placeholder={t("Common-Description")} />
            </Form.Item>
            <Form.Item
              className={styles.newItem}
              label={t("Common-InstructionsForUse")}
              name="instructionsForUse"
              initialValue={reward?.instructionsForUse}
            >
              <Input placeholder={t("Common-InstructionsForUse")} />
            </Form.Item>
            <div className={styles.formItemsWrapper}>
              <Form.Item
                className={styles.doubleItemLine}
                label={t("Common-Value")}
                name="value"
                initialValue={reward?.value}
              >
                <InputNumber
                  className={styles.inputNumber}
                  min={0}
                  placeholder={t("Common-Value")}
                />
              </Form.Item>
              <Form.Item
                className={styles.doubleItemLine}
                label={t("Common-PointsValue")}
                name="pointsValue"
                initialValue={reward?.pointsValue}
              >
                <InputNumber
                  className={styles.inputNumber}
                  min={0}
                  placeholder={t("Common-PointsValue")}
                />
              </Form.Item>
            </div>
            <div className={styles.formItemsWrapper}>
              <Form.Item
                className={styles.doubleItemLine}
                label={t("Common-LimitGlobal")}
                name="globalLimit"
                initialValue={reward?.globalLimit}
              >
                <InputNumber
                  className={styles.inputNumber}
                  min={0}
                  placeholder={t("Common-LimitGlobal")}
                />
              </Form.Item>
              <Form.Item
                className={styles.doubleItemLine}
                label={t("Common-LimitMember")}
                name="memberLimit"
                initialValue={reward?.globalLimit}
              >
                <InputNumber
                  className={styles.inputNumber}
                  min={0}
                  placeholder={t("Common-LimitMember")}
                />
              </Form.Item>
            </div>
            <div className={styles.formItemsWrapper}>
              <Form.Item
                className={styles.doubleItemLine}
                label={t("Common-LimitMemberUse")}
                name="memberUseLimit"
                initialValue={reward?.memberUseLimit}
              >
                <InputNumber
                  className={styles.inputNumber}
                  min={0}
                  placeholder={t("Common-LimitMemberUse")}
                />
              </Form.Item>
              <Form.Item
                className={styles.doubleItemLine}
                label={t("Common-ExpiredDate")}
                name="expirationDate"
                initialValue={
                  reward?.expirationDate
                    ? moment(reward?.expirationDate, "YYYY-MM-DD")
                    : null
                }
              >
                <DatePicker
                  className={styles.dateSelector}
                  showTime={false}
                  format="YYYY-MM-DD"
                />
              </Form.Item>
            </div>
            <Form.Item
              label={t("Common-Valid")}
              name="validity"
              className={styles.doubleItemLine}
              initialValue={[
                reward?.validityStart
                  ? moment(reward?.validityStart, "YYYY-MM-DD")
                  : null,
                reward?.validityEnd
                  ? moment(reward?.validityEnd, "YYYY-MM-DD")
                  : null,
              ]}
            >
              <DatePicker.RangePicker
                className={styles.inputNumber}
                placeholder={[t("Common-ValidFrom"), t("Common-ValidTo")]}
              ></DatePicker.RangePicker>
            </Form.Item>
            <div className={styles.formItemsWrapper}>
              <Form.Item
                label={t("Common-Brands")}
                name="brandIds"
                className={styles.doubleItemLine}
                initialValue={reward?.brands.map((brand: any) => brand.id)}
              >
                <Select
                  mode="multiple"
                  allowClear
                  onSearch={handleSearchBrands}
                  placeholder={t("Common-SearchByName")}
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  defaultValue={reward?.brands?.map((brand: any) => brand.id)}
                  disabled={!canEdit}
                >
                  {getOptions(brands)}
                </Select>
              </Form.Item>
              <Form.Item
                className={styles.doubleItemLine}
                label={t("Common-Categories")}
                name="categoryIds"
                initialValue={reward?.categories.map((cat: any) => cat.id)}
              >
                <Select
                  mode="multiple"
                  allowClear
                  showSearch
                  onSearch={handleSearchCategories}
                  placeholder={t("Common-SearchByName")}
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  defaultValue={reward?.categories.map((cat: any) => cat.id)}
                  style={{ width: "100%" }}
                >
                  {getOptionsCategories(categories)}
                </Select>
              </Form.Item>
            </div>
            <div className={styles.formItemsWrapper}>
              <Form.Item
                label={t("Common-ClientSegments")}
                name="clientSegmentIds"
                className={styles.doubleItemLine}
                initialValue={reward?.clientSegments.map((cl: any) => cl.id)}
              >
                <Select
                  mode="multiple"
                  allowClear
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  defaultValue={reward?.clientSegments.map((cl: any) => cl.id)}
                  onSearch={handleSearchClientSegments}
                  placeholder={t("Common-SearchByName")}
                >
                  {getOptions(clientSegments)}
                </Select>
              </Form.Item>
              <Form.Item
                className={styles.doubleItemLine}
                label={t("Common-Level")}
                name="levelIds"
                initialValue={reward?.levels.map((lev: any) => lev.id)}
              >
                <Select
                  allowClear
                  mode="multiple"
                  showSearch
                  placeholder={t("Common-SearchByName")}
                  onSearch={handleSearchLevels}
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  defaultValue={reward?.levels.map((lev: any) => lev.id)}
                >
                  {getOptions(levels)}
                </Select>
              </Form.Item>
            </div>

            <div className={styles.formItemsWrapper}>
              <Form.Item
                className={styles.doubleItemLine}
                label={t("Common-CodeType")}
                name="codeType"
                initialValue={reward?.codeType}
              >
                <Select>
                  <Select.Option value="ALPHANUMERIC">
                    {t("Common-Alphanumeric")}
                  </Select.Option>
                  <Select.Option value="CHARACTERS">
                    {t("Common-Characters")}
                  </Select.Option>
                  <Select.Option value="NUMBERS">
                    {t("Common-Numbers")}
                  </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                className={styles.doubleItemLine}
                label={t("Common-CodeLength")}
                name="codeLength"
                initialValue={reward?.codeLength}
              >
                <InputNumber
                  className={styles.inputNumber}
                  min={0}
                  placeholder={t("Common-CodeLength")}
                />
              </Form.Item>
            </div>
            <Form.Item
              className={styles.doubleItemLine}
              label={t("Common-CodePrefix")}
              name="prefix"
              initialValue={reward?.prefix}
            >
              <Input placeholder={t("Common-CodePrefix")} />
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ViewReward;
